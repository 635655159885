import "../css/whitepaper.css"
import midjourney from "../img/midjourney.png"
import chatgpt from "../img/chatgpt.jpg"
import chatgpt4 from "../img/chatgpt4.jpg"

function PrivacyPolicy() {
    return (
<div id='whitepaper'>

<h1>Whitepaper</h1>
<hr/>
<p><strong>Ascendance.dev: The Web3 Marketplace Incorporating Web2 & Real Utility</strong></p>
<p><strong>Version 1.0</strong><br></br><strong>Date: 10/6/2023</strong></p>
<strong>Creating a robust web3 marketplace to empower holders!</strong>
<hr/>
<h2><strong>Table of Contents</strong></h2>
<ol>
<li>Executive Summary</li><br/>
<li>Introduction</li><br/>
<li>Market Overview</li><br/>
<li>Ascendance Platform
<ul>
<li>4.1 Web2 Marketplace</li><br/>
<li>4.2 Web3 Integration</li><br/>
</ul>
</li><br/>
<li>Product Offerings</li><br/>
<li>Tokenomics
<ul>
<li>6.1 ASCENDANCE Token</li><br/>
<li>6.2 Token Utility</li><br/>
<li>6.3 Token Distribution</li><br/>
</ul>
</li><br/>
<li>Technology Stack</li><br/>
<li>Roadmap</li><br/>
<li>Legal and Compliance</li><br/>
<li>Conclusion</li><br/>
</ol>
<hr/>
<h2><strong>1. Executive Summary</strong></h2>
<p>Ascendance.dev aims to craft a unique, decentralized marketplace for digital assets that seamlessly merges the simplicity and user-friendliness of Web2 technologies (Payments with Stripe) with the security and decentralized power of Web3, inclusivity and real-time cryptographic proof of ownership. With a diverse product range from 3D models, digital prompts, stock images, educational courses, and software tools, Ascendance.dev looks to empower the creative community, freelancers, and businesses by providing a platform that allows them to buy, sell, and also earn from the platform&rsquo;s earnings through innovative tokenomics.</p>
<hr/>
<h2><strong>2. Introduction</strong></h2>
<p>With the growing demand for digital assets and the exponential rise of blockchain technology, there's a palpable need for a platform that can bridge the traditional digital marketplace with decentralized finance and asset ownership protocols. Ascendance.dev seeks to satisfy this need by providing a versatile platform that offers not just a wide array of digital assets but also bestows tangible financial empowerment to its user community through blockchain.</p>
<hr/>
<h2><strong>3. Market Overview</strong></h2>
<p>We want to create the most powerful Marketplace by creating a synergy and a bridge between web2-web3 and real utility tokens.</p>
<hr/>
<h2><strong>4. Ascendance Platform</strong></h2>
<h3><strong>4.1 Web2 Marketplace</strong></h3>
<p>The marketplace offers a plethora of digital assets like 3D models, prompts, stock images, educational materials, and software tools, streamlining the buying and selling process with a familiar, easy-to-use interface that integrates conventional and industry-standard payment systems (Stripe).</p>
<h3><strong>4.2 Web3 Integration</strong></h3>
<p>Purchases in the marketplace are not just transactions but also confer ownership and stakes via Solana-based NFTs and native ASCENDANACE DEV tokens. These blockchain-backed assets provide various utilities, including earning tokens and staking through holding Ascendance Dev Tokens.</p>
<hr/>
<h2><strong>5. Product Offerings</strong></h2>
<ul>
<li><strong>3D Models</strong>: For designers, architects, and developers.</li><br/>
<li><strong>Prompts</strong>: Creative and technical (prompt engineering).</li><br/>
<li><strong>Images</strong>: High-quality stock images for diverse applications.</li><br/>
<li><strong>Educational Content</strong>: Courses and classes spanning multiple disciplines.</li><br/>
<li><strong>Software Tools</strong>: Tools and plugins for enhanced digital productivity.</li><br/>
<li>Physical Products. (TBA).</li><br/>
</ul>
<hr/>
<h2><strong>6. Tokenomics</strong></h2>
<h3><strong>6.1 ASCENDANCE DEV&nbsp; Token</strong></h3>
<p>ASCENDANCE DEV, the native utility token of Ascendance.dev, is utilized to distribute 50% of the platform&rsquo;s profit among the asset-holding community and to govern the platform through our native token and creating a Liquidity Pool and buying tokens with 50% of earnings.</p>
<h3><strong>6.2 Token Utility</strong></h3>
<ul>
<li><strong>Profit Sharing</strong>: Owners of ASCENDANCE tokens are entitled to 50% of the platform's profits, distributed proportionally based on holdings on our native token.</li><br/>
<li><strong>Real Utility</strong>: Tokens will have rel utility on the platform to buy digital and physical assets.</li><br/>
<li><strong>Staking</strong>: Stake tokens to earn more ASCENDANCE and obtain other platform-specific perks.</li><br/>
<li>Exchange your tokens for NFTs so you can earn even more tokens</li><br/>
</ul>
<h3><strong>6.3 Token Distribution</strong></h3>
<ul>
<li><strong>Community Reward</strong>: 50%</li><br/>
<li><strong>Development Fund</strong>: 33%</li><br/>
<li><strong>Founders and Team</strong>: 17%</li><br/>
<li>&nbsp;</li><br/>
</ul>
<hr/>
<h2><strong>7. Technology Stack</strong></h2>
<p>Leveraging the efficiency and low transaction cost of the Solana blockchain, Ascendance.dev combines robust Web2 technologies (for marketplace functionality) with secure Web3 protocols (for decentralized finance and ownership features).</p>
<hr/>
<h2><strong>8. Roadmap</strong></h2>
<p>Prompt MARKETPLACE <em><strong>LIVE</strong></em></p>
<p>3D Assets MARKETPLACE -TBA</p>
<p>Music Assets - TBA</p>
<hr/>
<h2><strong>9. Legal and Compliance</strong></h2>
<p>Ascendance.dev operates with strict adherence to global legal and regulatory standards, ensuring the utmost protection for its users and contributors.</p>
<hr/>
<h2><strong>10. Conclusion</strong></h2>
<p>With a mission to empower the creative and hard-working individuals in the digital space, Ascendance.dev stands out as a pioneering marketplace that not only facilitates the buying and selling of digital assets but also redefines the notion of ownership and community rewards through decentralized finance. By bridging Web2 and Web3, Ascendance.dev is poised to reshape the landscape of digital trade and community ownership.</p>
<hr/>
<p><strong>Contact</strong><br></br>apesascendance@gmail.com</p>
<p><strong>Website</strong><br></br>https://ascendance.dev</p>

</div>
      
        
 );
}
export default PrivacyPolicy;