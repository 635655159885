import "../css/Content.css"
import midjourney from "../img/midjourney.png"
import chatgpt from "../img/chatgpt.jpg"
import chatgpt4 from "../img/chatgpt4.jpg"

function Content() {
    return (<>

<div className="grid-container-1"><div className="grid-item">
<h1 className="headline_courses">About the author:</h1>
<p>I'm a creative. I have a background in robotics. I grew up in the countryside of Mexico, my grandfather is from Tamaulipas, and one of my parents is from the US. I use AI for the pipeline but the concept art is part of my creation, I usually try to study hard anatomy before drawing paying close attention for forms, lines, shapes, rhythm, proportions. </p>
</div>
<center><img height={500} src="https://miro.medium.com/v2/resize:fit:4800/format:webp/1*onKvS6-jOzznJsLHeT8SEw.png"></img>
<p>Me and my grandfather.</p>
</center>

</div>
<h1>I'm building a video game Apes Ascendance as a solo indie dev! </h1>
<p><a href="https://myria.com/marketplace/collection/?id=799d49c2-5cb8-4280-a5ce-22f352c6cdf8">My Official NFT collection is available at Myria</a></p>

<div className="grid-container-3">
                <div className="grid-item">
                <div className="card"><a href="#">
                    
               <a href=""><img width="100%" src="https://arweave.net/-yLsVhX-NTZnRhRNFKYfegwUlKU8Sr16K4TPKfaysnc" alt="Avatar" /></a> 
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>I'm building Apes Ascendance.<b></b></h4><p>a video game as an indie dev</p>
            </div></a></div></div>

            <div className="grid-item">
                <div className="card"><a href="#">
                    
                <img width="100%" src="https://arweave.net/JO3jRlgtCJd8cgoZNjgiocoa6Z7lsXKZeusBQKchbBo" alt="Avatar" />
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>test animation.<b></b></h4><p>Pirate Apes + Dragon Cobra</p>
            </div></a></div></div>
            

            <div className="grid-item">
                <div className="card"><a href="#">
                    
                <img width="100%" src="https://arweave.net/dqnJMo0UvLTyR6gNkepGDcbwizWpSkrvzmBrPDSXWcA" alt="Avatar" />
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>For concept art.<b></b></h4><p>to 3D game ready!</p>
            </div></a></div></div>

</div>
<div className="grid-container-3">
                <div className="grid-item">
                <div className="card"><a href="#">
                    
               <a href=""><img width="100%" src="https://arweave.net/wid1cd_bWpWqAA5q5s7JeEj63fTWlauiz-D7SWwdec8" alt="Avatar" /></a> 
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>This is the Dragon Cobra: <b></b></h4><p>a non-human renaissance!</p>
            </div></a></div></div>


            <div className="grid-item">
                <div className="card"><a href="#">
                    
               <a href=""><img width="100%" src="https://arweave.net/Dqkypidzuy9kkJhHLUrd12oiNJja8mGHafOijLdlv98" alt="Avatar" /></a> 
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>BAD AI mastered: <b></b></h4><p>The Tree of Life!</p>
            </div></a></div></div>


            

                <div className="grid-item">
                <div className="card"><a href="#">
                    
               <a href=""><img width="100%" src="https://arweave.net/ck8i3OvZMTZqONTBIEjAq2o0Rjmuk4R2y36lcdzPImc" alt="Avatar" /></a> 
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#indiedev <b>web3</b></span><span className="time"> 🦧</span></div><br />
            <h4>In a dystiopian future: <b></b></h4><p>BAD AI take control of the biggest Universe Sector!</p>
            </div></a></div></div>



            </div>

            





        <div className="grid-container-1"><div className="grid-item">
            <h1 className="headline_courses">If you want to support the project check our books (available on Amazon):</h1></div></div>
            <div className="grid-container-3">
                <div className="grid-item">
                <div className="card"><a href="https://www.amazon.com/dp/B0CRNDR92N">
                    
                <img width="100%" src="https://arweave.net/_UsbIFQCEr35lamjBhOD3J72KbE_sJJSeabuuvfNTVc" alt="Avatar" />
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#dev <b>web3</b></span><span className="time"> 🦀</span></div><br />
            <h4>Solana Developer Foundations.<b></b></h4><p>Learn Solana Dewelopment</p>
            </div></a></div></div>


            <div className="grid-item">
                <div className="card"><a href="https://www.amazon.com/dp/B0CB7B62RG">
                    
                <img width="100%" src="https://arweave.net/1pcMSm40Hhl3vVjLZ1-kD6qBmTEKLumtnzzG2T1ypyY" alt="Avatar" />
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">#dev <b>Rust</b></span><span className="time"> 🦀</span></div><br />
            <h4>Rust Programming for Beginners.<b></b></h4><p>Simplified Version</p>
            </div></a></div></div>




            <div className="grid-item">
                <div className="card"><a href="https://www.amazon.com/dp/B0CBVPNND3">
                    
                <img width="100%" src="https://arweave.net/Uphb104gAgOHPIBAREkMoquDFZkandhNHDRxse_9WGs" alt="Avatar" />
                
            <div className="container title_course"><div className="topContainer">
                <span className="students">Fiction <b>Book</b></span><span className="time"> </span></div><br />
            <h4>Anubis Return.<b></b></h4><p>Prayings for the Return of Jackal Headed God</p>
            </div></a></div></div>

            
   
            
            </div>

            <div className="grid-container-1"><div className="grid-item">
            <h1 className="headline_courses">If you want to get news about my video game and new books releases:</h1>
           <a href="/signup/"> <button className="buttonSU btnSignUp">Sign Up/ Sign In</button></a>
            </div></div>

           
        
    </>  );
}

export default Content;