import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where,setDoc, getDocs,getDoc, DocumentData, doc, getFirestore, FieldValue, serverTimestamp, updateDoc, limit  } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/profile.css"
import { getAuth,signOut,sendEmailVerification, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, User } from "firebase/auth";

function SignUp() {
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const [emailSentS, setEmailSentS] = useState("")
const [password, setPassword] = useState("")
const [email, setEmail] = useState("")
const [titlePrompt, setTitlePrompt] = useState("")
const [uid, setUid] = useState("")
const [name, setName] = useState("")
const [lastName, setLastName] = useState("")
const [signActiveB, setSignActiveB] = useState(true)
const [isVerified, setIsVerified] = useState (false)
const [profileExistsB, setProfileExistsB] = useState(false)
const [firebaseUser, setFirebaseUser] = useState<User>()
const [claimedTokens, setClaimedTokens] = useState(false)
const [image, setImage] = useState("")

const [slug, setSlug] = useState("")
const [title, setTitle] = useState("")

const [titles, setTitles] = useState<string[]>([])
const [slugs, setSlugs] = useState<string[]>([])
const [images, setImages] = useState<string[]>([])

const [titles3D, setTitles3D] = useState<string[]>([])
const [slugs3D, setSlugs3D] = useState<string[]>([])
const [images3D, setImages3D] = useState<string[]>([])



const [promptOwned, setPromptOwned] = useState<any[]>([])
const [model3DOwned, setModel3DOwned] = useState<any[]>([])
const [promptsOwned, setPromptsOwned] = useState<any[]>([])
const [models3DOwned, SetModels3DOwned] = useState<any[]>([])
const [customPrompt, setCustomPrompt] = useState(0)
const [totalPrompts, setTotalPrompts] = useState(0)
const [total3DModels, setTotal3DModels] = useState(0)
const [currentPrompt, setCurrentPrompt] = useState(0)
const [currentModel3D, setCurrentModel3D] = useState(0)
const [slugOwned, setSlugOwned] = useState("")

let db = getFirestore(app)
  useEffect(() => {
    const getPrompts = async () => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFirebaseUser(user)
          if (user.uid== "" || user.isAnonymous){
  // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          setEmail(user.email == null? "": user.email)
          setIsVerified(user.emailVerified)
          console.log(uid)
          setUid(uid)
          console.log('i fire once');
  
          }else{
            console.log("io"+ user.uid)
            setUid(user.uid)
            getPromptWithSlug()
            getProfileIfExists(user.uid)
            
          }
        
          // ...
        } else {
          // User is signed out
          // ...
          window.location.replace("/signup/")
        }
      });
      
    }
    getPrompts()

    
    
  }, [])
function logout(){
signOut(auth).then(() => {
  // Sign-out successful.
}).catch((error) => {
  // An error happened.
});
}
async function claimTokens(){
console.log("claiming tokens")
const userRef = doc(db, "users", uid);
if (!profileExistsB){

await setDoc(userRef, {
  ascendance_tokens: 100, 
  updatedAt: serverTimestamp(),
  cretedAt: serverTimestamp()

});

}else{

await updateDoc(userRef, {
  ascendance_tokens: 100, 
  updatedAt: serverTimestamp()

});

}

window.location.reload()

}
async function SaveProfile(){

// if no exists if verified emal +100 else 0

// if exists update

//only save name, last name
if (uid != ""){
  
  if (profileExistsB==true){
    const washingtonRef = doc(db, "users", uid);

    // Set the "capital" field of the city 'DC'
    await updateDoc(washingtonRef, {
      name: name, 
      lastName: lastName, 
      updatedAt: serverTimestamp()

    });
 
  }else{
    const washingtonRef = doc(db, "users", uid);

    // Set the "capital" field of the city 'DC'
    await setDoc(washingtonRef, {
      name: name, 
      lastName: lastName, 
      updatedAt: serverTimestamp(),
      createdAt: serverTimestamp()

    });
  }
}else{
  console.log("uid don't exist")
}




}

async function reloadPage(){
window.location.reload()
}

async function getProfileIfExists(theUid: string){
  console.log("ii")
if (theUid != ""){

  const docRef = doc(db, "users",theUid);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    console.log("Document data:", docSnap.data());
    setProfileExistsB(true)
    setName(docSnap.data().name)
    setLastName(docSnap.data().lastName)

   
    let arrSlugs: string[] = []
    let arrImages: string[] = []
    let arrTitles: string[] = []

       
    let arrSlugs3D: string[] = []
    let arrImages3D: string[] = []
    let arrTitles3D: string[] = []



      let counterI = 0;
      let counterI3D = 0;
    for (const [key, valueS] of Object.entries(docSnap.data())) {
      if (key.startsWith("prompt_")){
        console.log(`: ${valueS}`);
        arrSlugs.push(valueS)
        const promptRef = doc(db, "prompt", valueS)
        const q = query(collection(db, "prompt"), where("slug", "==",valueS ), limit(1));
        let counterII =0
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          arrTitles.push(doc.data().title)
          arrImages.push(doc.data().twitter_image)

          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          if (counterII==0){
            //setTitleOwned(doc.data().title)
    
          }
          counterII+=1;
        });
          //            setPosts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          setPromptOwned(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        promptsOwned.push(promptOwned)
        setPromptsOwned(promptsOwned)
       
        counterI+=1;

  

      }else if (key.startsWith("3D_model_")){
let theId = key.substring(9,key.length);
console.log(theId)

        console.log(`: ${valueS}`);
        const q = query(collection(db, "models_3D"), where("slug", "==" ,theId ), limit(1));
        let counterII =0
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          arrTitles3D.push(doc.data().title)
          arrImages3D.push(doc.data().twitter_image)
          arrSlugs3D.push(doc.data().slug)

          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          if (counterII==0){
    
          }
          counterII+=1;
        });
          //            setPosts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          setModel3DOwned(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        models3DOwned.push(model3DOwned)
        setModel3DOwned(models3DOwned)
       
        counterI3D+=1;

  

      
      }
  
    }
    console.log("title l: "+ arrTitles.length)
    return setTotalPrompts(counterI), setTitles(arrTitles), setImages(arrImages), setSlugs(arrSlugs), setCurrentPrompt(0), 
    setTotal3DModels(counterI3D), setTitles3D(arrTitles3D), setImages3D(arrImages3D), setSlugs3D(arrSlugs3D), setCurrentModel3D(0)
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
    setProfileExistsB(false)
    return setTotalPrompts(0)
  }
}

}
function sendVerificationEmail(){
sendEmail()
}
async function sendEmail(){
if (firebaseUser != null){
  sendEmailVerification(firebaseUser)
  .then(() => {
    // Email verification sent!
    // ...

    console.log("sent")
    setEmailSentS("Email Sent, check your inbox")

  });
    // ...
}
}

function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

async function getPromptWithSlug(){
  if (getCookie("slug") != null && getCookie("slug")!= "" && getCookie("slug").length>3){
    setSlug(getCookie("slug"))
    const promptRef = doc(db, "prompt", getCookie("slug"))
    const promptSnap = await getDoc(promptRef);

    if (promptSnap.exists()){
      setTitlePrompt(promptSnap.data().title)
      setImage(promptSnap.data().twitter_image)
    }else{
      console.log("doesn't exist")
    }

  }
}
function nextPrompt(){

  if (currentPrompt< titles.length-1){
    return setCurrentPrompt(currentPrompt+1)
  }else{
    return setCurrentPrompt(currentPrompt)

  }
}function next3DModel(){

  if (currentModel3D< titles3D.length-1){
    console.log(images3D[currentModel3D])
    return setCurrentModel3D(currentModel3D+1)
  }else{
    return setCurrentModel3D(currentModel3D)

  }
}


function previous3DModel(){
  if (currentModel3D> 0){
    return setCurrentModel3D(currentModel3D-1)
  }else{
    return setCurrentModel3D(0)

  }
}

function previousPrompt(){
  if (currentPrompt> 0){
    return setCurrentPrompt(currentPrompt-1)
  }else{
    return setCurrentPrompt(0)

  }
}
    return (
      <>
      <div id='profile'>
<h2>Profile</h2>
<h2>My cart 🛒</h2>
<div className='card'><a href={"/prompt/"+slug+"/"}>{titlePrompt}<img style={{padding:"5px"}} width="75px" src={image}/></a><br /></div>
<h2>My Prompts <span><strong>({totalPrompts})</strong></span></h2>
<div className={totalPrompts==0? "grid-container-3 hidden": "grid-container-3"}>

<div className="grid-item"><button onClick={previousPrompt} className='buybutton'>Back </button>
</div>
<div className="grid-item">


            <div className="grid-item"><div className="card">
              <a href={"/prompt/"+slugs[currentPrompt]+"/" }>
                <img width="100%" src={images[currentPrompt]} alt="Avatar" />
            <div className="container title_course"><div className="topContainer">
              <span className="students">Prompts <b>3</b></span><span className="time"> ⛵ </span></div>
              <h4>{title}<b></b></h4><p>{titles[currentPrompt]}</p></div></a></div></div>
  

</div>
  <div className="grid-item">
    <button onClick={nextPrompt} className='buybutton'>Next </button>

</div>
</div>



<h2>My 3D Models <span><strong>({total3DModels})</strong></span></h2>

<div className={total3DModels==0? "grid-container-3 hidden": "grid-container-3"}>

<div className="grid-item"><button onClick={previous3DModel} className='buybutton'>Back </button>
</div>
<div className="grid-item">


            <div className="grid-item"><div className="card">
              <a href={"/3d_model/"+slugs3D[currentModel3D]+"/" }>
                <img width="100%" src={images3D[currentModel3D]} alt="Avatar" />
            <div className="container title_course"><div className="topContainer">
              <span className="students">3D <b>Asset</b></span><span className="time"> 
              <button id='claimNFT'>Claim NFT (soon)</button> </span></div>
              <h4>{title}<b></b></h4><p>{titles3D[currentModel3D]}</p></div></a></div></div>
  

</div>
  <div className="grid-item">
    <button onClick={next3DModel} className='buybutton'>Next </button>

</div>
</div>



    
        <input value={name} onChange={e=> setName(e.target.value) } placeholder='Name'></input><br/>
        <input value={lastName} onChange={e=> setLastName(e.target.value) } placeholder='Last Name'></input>
        <br/>
<span>email: </span> <span>{email}</span>
<br/>
<span>Is email verified: {isVerified? "Yes": "No"}</span>
<br/>
<button className={isVerified?"hidden sendProfile":"sendProfile"} onClick={sendVerificationEmail} disabled={isVerified?true: false}>Send Verification Email</button><br/>
<h2>{emailSentS}</h2>
{/** <span>Your Ascendance.dev Tokens: {isVerified? "100": "0 tokens (please verify email to claim 100 tokens)"}</span><span className={claimedTokens?"":"hidden"}>✅</span>
<button className={claimedTokens? "hidden":"activeClaim"} onClick={claimTokens} disabled={!isVerified? true: false}>Claim 100 Tokens</button>
 */}<button onClick={reloadPage} >Reload</button>
<br/>
<br></br>
<button id='saveProfile' onClick={SaveProfile}>Save</button>
<button id='logoutProfile' onClick={logout}>Logout</button>

      </div>
      
      </>
    );
  }
  export default SignUp;