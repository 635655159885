import {Link} from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from 'react'
import { title } from 'process';
import { getAuth, onAuthStateChanged, User, signInAnonymously } from "firebase/auth";

import { left } from '@popperjs/core';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Category from '../components/Category';
import "../css/post.css"
import app from "../firebaseapp"
import TopArticles from './TopArticles';
import { hostname } from 'os';
function Post() {
  let [title, setTitle] = useState("")
  let [slug, setSlug] = useState("");
  let [category, setCategory] = useState("")
  let [image, setImage] = useState("https://www.arweave.net/elAbIMOD74awWYccK7wkGLmT-1O19Hqrq6aULqb5bo8?ext=gif")
  let [imagePinterest, setPinterestImage] = useState("")
  let [loadB, setLoadB] = useState(false)
  let [amazonProduct, setAmazonProduct] = useState('<iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=serpentacad04-20&language=en_US&marketplace=amazon&region=US&placement=B0BGPF1ZY5&asins=B0BGPF1ZY5&linkId=fceca2be56e178b4fdb12d92e486904c&show_border=true&link_opens_in_new_window=true"></iframe>')
  let [amazonProductDescription, setAmazonProductDescription] = useState('Check our first book! Dragon and Cobra: Origins of the Ancestral Dragons delves into the fantastical world of two legendary creatures, exploring their histories, mythologies, and cultural impact. The book uncovers their shared origins and enduring legacy.')
  let [labels, setLabels] = useState([])
  let [displayModal, setDisplayModal] = useState("none")
  const [youtubeID, setYoutubeId] = useState('')
  let [domain, setDomain] = useState("localhost")
  const [url, setUrl] = useState("https://ascendance.dev")
  let [isAnonymous, setIsAnonymous] = useState(true)
  let [validAuth, setValidAuth] = useState(false)
  function getCurrentURL () {
    return setUrl(window.location.href)
  }
  const auth = getAuth(app);
let db = getFirestore(app)
  let [description, setDescription] = useState("")
  useEffect(() => {
//get if user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(uid)
    console.log(user)
    setValidAuth(true)
    setIsAnonymous(user.isAnonymous)
    console.log("isAnonymous:" +user.isAnonymous)
    // ...
  } else {
    // User is signed out
    // ...
  }
});


  //get domain

  let domainS = window.location.hostname;
  console.log(domainS)
  setDomain("domains: "+domainS)
  let postsS = "unknown"
  let metaTagsS = "unknown"
  switch(domainS){
    case "apesascendance.com":
      postsS = "apesposts"
      metaTagsS ="apes_meta_tags"
      break;
      case "ascendance.dev":
        metaTagsS= "meta_tags"
        postsS = "posts"
        break;
      case "localhost":
        console.log("EUUUUUU")
        postsS = "posts"
        metaTagsS="apes_meta_tags"
  
      break;
  }

  //end getDomain


    getCurrentURL()
    if (loadB){
      return
    }else{
      loadB = true
      setLoadB(loadB)
      const getPosts = async () => {
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /p/1
        slug = currentpathName.substring(3, currentpathName.length-1)
        setSlug(slug)
        console.log("slug: "+slug)
        // /p/crow-spirit-visions/p/crow-spirit-visions/
        if (slug.includes("/p/")){
          var slug2 =slug.substring(0,slug.lastIndexOf("p/"))
          if (slug2.includes("/p/")){
          console.log(window.location.hostname+slug2)
            window.location.href=window.location.hostname+slug2
          }

        }


        const docRef = doc(db, postsS, slug);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          title = docSnap.data().title
          image = docSnap.data().image
          description = docSnap.data().description
          setTitle(title)
          setImage(image)
          setPinterestImage(image)
          if (docSnap.data().labels != null){
            setLabels(docSnap.data().labels)

          }
          setCategory("Article")
          if (docSnap.data().amazon != null && docSnap.data().amazon != "")
          {
            setAmazonProduct(docSnap.data().amazon)
            console.log(amazonProduct)
          }
          if (docSnap.data().amazon_description != null && docSnap.data().amazon_description != "")
          {
            setAmazonProductDescription(docSnap.data().amazon_description)
            console.log(amazonProductDescription)
          }
          setYoutubeId(docSnap.data().youtubeId)
          console.log(docSnap.data().youtubeId)
          setDescription(description)
        
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
        getPosts()
    }
  

   //signUpAnonymously

  
  },[imagePinterest]);
  async function GetPost(){


  }
  function signUpAnonymously(){
    //if no AUTH signup anonymously

    //a) Check AUTH
if (!validAuth){
//signup anonymously
signInAnonymously(auth)
  .then(() => {
    // Signed in..
    console.log("signed anonymously")
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
}else{
    if (isAnonymous== true){

    }if (isAnonymous== false){

    }

}


    //b) IF no AUTH signup anonymously
  }

  function closeModal(){
    
    setDisplayModal("none")
  }

    return (
      <>
        <main>
        <div className='container_article'>

        <img style={{width:"100%"}} width="100%" src={image} className="img-fluid" alt="..."/>
        <br>
        </br>
</div>
        {imagePinterest!=""?
<a     data-pin-count="true" 
       className="pinterestSave" 
       data-pin-media={imagePinterest} 
       data-pin-description={description} 
       data-pin-url={"https://ascendance.dev/p/"+slug+"/"} 
       href={"https://www.pinterest.com/pin/create/button/?url="+url+"&title="+encodeURIComponent(title)+"&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)} 
       data-pin-do="buttonPin">
        <img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"
  ></img>

       <span className='pinText'>Save</span>   </a>:"" }
       

    



       <div className='container_article'>

          <h2>{title}</h2>
          </div>
          <div className='container_article'>

          <div className='divcontent' style={{alignContent:left}} dangerouslySetInnerHTML={{__html: description}}    />
          <br></br><br></br>
         <div id="labels">{labels.map(label => {
    return <span className='label'>{label}</span>
}) 

}</div>
<br/><br/><br/>
</div>
<div className='container_article'>

          <iframe className='video'
        title='Youtube player'
    style={{width:"100%"}}
        height={400}
        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
</iframe>

</div>

        </main>
         
<div style={{display: displayModal}} id="myModal" className="modal">

  <div className="modal-content">
    <span onClick={closeModal} className="close">&times;</span>
    <p>Subscribe to Email and Push Notifications for FREE BOOKS</p>
  </div>

</div>
        <div className='content'>
        
 


          <div> <h2>Share this article to show love ❤️:</h2></div>
<br></br>

       <br />  <br />  <br />
      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={category}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

<TopArticles></TopArticles>
    </div>
        <nav>
          <Link to="/about">About</Link>
        </nav>
        <footer className="footerSocial">
          Help us spread love! ❤️
        {imagePinterest!=""?
<a 
       data-pin-count="true" 
       className="pinterestSave" 
       data-pin-media={imagePinterest} 
       data-pin-description={description} 
       data-pin-url={url+"/p/"+slug+"/"} 
       href={"https://www.pinterest.com/pin/create/button/?title="+encodeURIComponent(title)+
        "&url="+url+"&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)+"&title="+encodeURIComponent(title)} 
       data-pin-do="buttonPin">
        <img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"
  ></img>
       <span className='pinText'>Save</span>   </a>:"" }
</footer>
      </>
      
    );
  }
  export default Post;