import {Link} from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection, doc, getDoc, updateDoc} from "firebase/firestore";
import {useEffect, useState} from 'react'
import { title } from 'process';
import { getAuth, onAuthStateChanged, User, signInAnonymously } from "firebase/auth";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { left } from '@popperjs/core';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Category from '../components/Category';
import "../css/post.css"
import app from "../firebaseapp"

import { hostname } from 'os';
function Post() {

  interface classIA {
    title: string;
    slug: string;
  }
  let [lessonsSlugs, setLessonsSlugs] = useState<string[]>([])
  
  let [title, setTitle] = useState("")
  let [gpt, setGpt] = useState("#")
  let [slug, setSlug] = useState("");
  let [category, setCategory] = useState("")
  let [image, setImage] = useState("https://www.arweave.net/elAbIMOD74awWYccK7wkGLmT-1O19Hqrq6aULqb5bo8?ext=gif")
  let [imagePinterest, setPinterestImage] = useState("")
  let [loadB, setLoadB] = useState(false)
  let [amazonProduct, setAmazonProduct] = useState('<iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=serpentacad04-20&language=en_US&marketplace=amazon&region=US&placement=B0BGPF1ZY5&asins=B0BGPF1ZY5&linkId=fceca2be56e178b4fdb12d92e486904c&show_border=true&link_opens_in_new_window=true"></iframe>')
  let [amazonProductDescription, setAmazonProductDescription] = useState('Check our first book! Dragon and Cobra: Origins of the Ancestral Dragons delves into the fantastical world of two legendary creatures, exploring their histories, mythologies, and cultural impact. The book uncovers their shared origins and enduring legacy.')
  let [labels, setLabels] = useState([])
  let [codeString, setCodeString] = useState("")
  let [programmingLanguage, setProgrammigLanguage] = useState("rust")
  let [coinForArticleB, setCoinForArtcileB] = useState(false)
  let [displayModal, setDisplayModal] = useState("none")
  let [nextUrl, setNextUrl] = useState("#")
  let [previousUrl, setPreviousUrl] = useState("#")

  const [youtubeID, setYoutubeId] = useState('')
  let [domain, setDomain] = useState("localhost")
  const [url, setUrl] = useState("https://ascendance.dev")
  let [isAnonymous, setIsAnonymous] = useState(true)
  let [validAuth, setValidAuth] = useState(false)
  let [uid, setUid] = useState("")
  function getCurrentURL () {
    return setUrl(window.location.href)
  }
  const auth = getAuth(app);
let db = getFirestore(app)
  let [description, setDescription] = useState("")
  useEffect(() => {

    async function updateCoins(uid: string){
      const washingtonRef = doc(db, "users", uid );

      await updateDoc(washingtonRef, {
["coin_"+slug]: true
      });
    }
//get if user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(uid)
    setUid(uid)
    console.log(user)
    setValidAuth(true)
    setIsAnonymous(user.isAnonymous)
    console.log("isAnonymous:" +user.isAnonymous)

    updateCoins(uid)
    // ...
  } else {
    // User is signed out
    // ...
  }
});


  //get domain

  let domainS = window.location.hostname;
  console.log(domainS)
  setDomain("domains: "+domainS)
  let postsS = "unknown"
  let metaTagsS = "unknown"
  switch(domainS){
    case "apesascendance.com":
      postsS = "apes_lessons"
      metaTagsS ="apes_lessons_meta_tags"
      break;
      case "ascendance.dev":
        metaTagsS= "lessons_meta_tags"
        postsS = "lessons"
        break;
      case "localhost":
        console.log("EUUUUUU")
        postsS = "lessons"
        metaTagsS="apes_lessons_meta_tags"
  
      break;
  }

  //end getDomain


    getCurrentURL()
    if (loadB){
      return
    }else{
      loadB = true
      setLoadB(loadB)
      const getPosts = async () => {
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /p/1
        if (slug.endsWith("/")){
          slug = currentpathName.substring(3, currentpathName.length-1)

        }else{
          slug = currentpathName.substring(3, currentpathName.length)

        }
        setSlug(slug)
        console.log("slug: "+slug)
        // /p/crow-spirit-visions/p/crow-spirit-visions/
        if (slug.includes("/l/")){
          var slug2 =slug.substring(0,slug.lastIndexOf("l/"))
          if 
          (slug2.includes("/l/")){
          console.log(window.location.hostname+slug2)
            window.location.href=window.location.hostname+slug2
          }

        }


        const docRef = doc(db, postsS, slug);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          title = docSnap.data().title
          image = docSnap.data().image
          description = docSnap.data().description
          setTitle(title)
          setImage(image)
          setPinterestImage(image)
          if (docSnap.data().labels != null){
            setLabels(docSnap.data().labels)

          }
          console.log("existsB?")

          if (docSnap.data().classes_0 != null && 
          docSnap.data().classes_1 != null &&
          docSnap.data().classes_2 != null )
          {
            console.log("existsB")
            let arrL:string[] = []
            for (var i=0; i < docSnap.data()["classes_0"].length; i++){
        arrL.push(docSnap.data()["classes_0"][i].slug+"")
        console.log("d")
            }
            for (var io=0; io < docSnap.data()["classes_1"].length; io++){
              arrL.push(docSnap.data()["classes_1"][io].slug+"")
                  }

                   for (var ia=0; ia < docSnap.data()["classes_2"].length; ia++){
              arrL.push(docSnap.data()["classes_2"][ia].slug+"")
                  }
            setLessonsSlugs(arrL)
console.log("lessonsSlugs:" +arrL)
            //get previous and next slug
            //get current index
            let currentSlug:number = arrL.indexOf(slug)
console.log("currentSlug:"+currentSlug)
            //if slug >0
            if (currentSlug == 0){
              setNextUrl("/l/"+arrL[1])
              setPreviousUrl("#")


            }
            else if (currentSlug > 0 && currentSlug < arrL.length -1){
              setNextUrl("/l/"+arrL[currentSlug+1])
              setPreviousUrl("/l/"+arrL[currentSlug-1])

            }
            else{
              setNextUrl("#")
              setPreviousUrl("/l/"+arrL[currentSlug-1])

            }

          }
          if (docSnap.data()["coin_"+slug]!= null && uid != "" ){
            // Set the "capital" field of the city 'DC'
       
          }
          if (docSnap.data().gpt != null){
            setGpt(docSnap.data().gpt)

          }
          setCategory("Article")
          if (docSnap.data().codeString != null && docSnap.data().codeString != "")
          {
            setCodeString(docSnap.data().codeString)
          }
          if (docSnap.data().amazon != null && docSnap.data().amazon != "")
          {
            setAmazonProduct(docSnap.data().amazon)
            console.log(amazonProduct)
          }
          if (docSnap.data().amazon_description != null && docSnap.data().amazon_description != "")
          {
            setAmazonProductDescription(docSnap.data().amazon_description)
            console.log(amazonProductDescription)
          }
          setYoutubeId(docSnap.data().youtubeId)
          console.log(docSnap.data().youtubeId)
          setDescription(description)
        
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
        getPosts()
    }
  

   //signUpAnonymously

  
  },[imagePinterest]);
  async function GetPost(){


  }
  function signUpAnonymously(){
    //if no AUTH signup anonymously

    //a) Check AUTH
if (!validAuth){
//signup anonymously
signInAnonymously(auth)
  .then(() => {
    // Signed in..
    console.log("signed anonymously")
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
}else{
    if (isAnonymous== true){

    }if (isAnonymous== false){

    }

}


    //b) IF no AUTH signup anonymously
  }

  function closeModal(){
    
    setDisplayModal("none")
  }

    return (
      <>
        <a href={previousUrl} id="previousB">Previous</a>
<a href={nextUrl} id='nextB'>Next</a> 
        <main>
      


       <div className="grid-container-3 bgmaincard">
       
<div className="grid-item bgmaincard" >
<div className='card' >
<img className='card' src={image} alt={title} />
</div>
</div>


<div className="grid-item">
<h2 className='promptTitle'>{title}</h2>



<div className='grid-container-2'>
<div className='grid-item'></div>

<div className='grid-item'></div>


</div>
<div className="grid-item">
< a href={gpt} ><button className= "buybutton"  >GET GPT Tutor</button></a>
<br/>
<p>** Free Tutor if you have a CHATGPT4 Plus Membership</p>
<br/><hr />
</div>

</div>
<div className="grid-item">
</div>
</div>


        <br>
        </br>
        <iframe className='video'
        title='Youtube player'
        width={600}
    
        height={400}
        sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
        src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}>
</iframe>
       <br></br>




        {imagePinterest!=""?
<a data-pin-count="true" 
className="pinterestSave" 
data-pin-media={imagePinterest} 
data-pin-description={description} 
data-pin-url={"https://ascendance.dev/l/"+slug+""} 
href={"https://www.pinterest.com/pin/create/button/?url=https://ascendance.dev/l/"+slug+"&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)} 
data-pin-do="buttonPin">
<img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"></img>

       <span className='pinText'>Save</span>   </a>:"" }
       

      

          <h2>{title}</h2>
          <div className='divcontent' style={{alignContent:left}} dangerouslySetInnerHTML={{__html: description}}    />
         <h2>Code Example Exercise</h2>
         <SyntaxHighlighter  language="javascript" >
      {codeString}
    </SyntaxHighlighter>

         
          <br></br><br></br>
          <a href={previousUrl} id="previousB">Previous</a>
<a href={nextUrl} id='nextB'>Next</a> 
          <h2>Top Classes</h2>

<div className="tarjeta">
<div className="contenido">
<div className="ladoIzq">
<a href="https://skl.sh/46cUEXF"><h2 className="titulo">Master 3D Alien Planets Creation using AI
</h2></a>
<div className="cuerpo">
<p className='pRec'>👽🌍🚀 Master 3D alien planet mapping with Midjourney AI, Adobe Substance 3D Sampler, and Blender! Join today! #3DMapping #AlienWorlds #Skillshare 🎨🖥️🌌 </p>
     <a className="btnRec" href="https://skl.sh/46cUEXF">Take Skillshare Class Free</a>
</div>
</div>
<div className="ladoDer">
<a href="https://skl.sh/46cUEXF">
<br /><br /><br /><br />
<img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/ovhz-Ri2Decuma2eim9FH1Wio8Pkhua9u2l1ly8t_5Q" /></a></div>
</div>
</div>



<div className="tarjeta">
<div className="contenido">
<div className="ladoIzq">
<a href="https://skl.sh/3SonC3p"><h2 className="titulo">Transform photos into 3D objects with Photogrammetry using AI
</h2></a>
<div className="cuerpo">
<p className='pRec'>Transform photos into 3D objects 📸💻🎨. Our Skillshare class on Photogrammetry using Adobe Substance 3D Sampler awaits you! #Adobe3D #Photogrammetry #Skillshare </p>
     <a className="btnRec" href="https://skl.sh/3SonC3p">Take Skillshare Class Free</a>
</div>
</div>
<div className="ladoDer">
<a href="https://skl.sh/3SonC3p">
<br /><br /><br /><br />
<img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/qypEGfML1D1Q_l5wGTnCUM9hZRykvv-YcWLnEIQygfc" /></a></div>
</div>
</div>


<div className="tarjeta">
<div className="contenido">
<div className="ladoIzq">
<a href="https://skl.sh/3FvhgHO"><h2 className="titulo">Boat Buoyancy on a Primitive Ocean Unreal Engine 5, Adobe Substance 3D Sampler and Midjouney
</h2></a>
<div className="cuerpo">
<p className='pRec'>
Master Unreal Engine 5, Adobe Substance 3D & Midjourney in our Skillshare class, Boat Buoyancy on a Primitive Ocean. 🌊🚤🏝️ Create games, films with realistic oceans! #GameDev #3DModeling #SkillshareClass
</p>
     <a className="btnRec" href="https://skl.sh/3FvhgHO">Take Skillshare Class Free</a>
</div>
</div>
<div className="ladoDer">
<a href="https://skl.sh/3FvhgHO">
<br /><br /><br /><br />
<img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/m2Z11tsqt7ztejLQi3-LJexwb1GgtFZOBFrO38HrCXY" /></a></div>
</div>
</div>

<div className='skillsharesub'>
          <a href='https://www.skillshare.com/en/r/user/serpentacademy?gr_tch_ref=on'>
    <h2>Get 1 Month Free Skillshare</h2>
    <h3>Unleash your creativity with Skillshare! Merge tech with art 🎨💻✨. Ignite your imagination, explore endless possibilities. #CreativityUnleashed #TechMeetsArt #SkillshareExperience
</h3>
    <img className='imgSkillShare' src="https://arweave.net/mUgjIs_emUqyWimDyNElt1wT3BMRkvUbf3TfWw-ARVs"></img>
    </a>
  </div>


         <div id="labels">{labels.map(label => {
    return <span className='label'>{label}</span>
}) 

}</div>
<br/><br/><br/>
         



        </main>
         
<div style={{display: displayModal}} id="myModal" className="modal">

  <div className="modal-content">
    <span onClick={closeModal} className="close">&times;</span>
    <p>Subscribe to Email and Push Notifications for FREE BOOKS</p>
  </div>

</div>
        <div className='content'>
        
 


          <div> <h2>Share this article to show love ❤️:</h2></div>
<br></br>

       <br />  <br />  <br />
      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={category}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

    </div>
        <nav>
          <Link to="/about">About</Link>
        </nav>
        <footer className="footerSocial">
          Help us spread love! ❤️
        {imagePinterest!=""?
<a 
       data-pin-count="true" 
       className="pinterestSave" 
       data-pin-media={imagePinterest} 
       data-pin-description={description} 
       data-pin-url={url+"/l/"+slug+""} 
       href={"https://www.pinterest.com/pin/create/button/?url="+url+"&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)} 
       data-pin-do="buttonPin">
        <img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"
  ></img>
       <span className='pinText'>Save</span>   </a>:"" }
</footer>
      </>
      
    );
  }
  export default Post;