import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { collection, query, where, getDocs, DocumentData, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import { Editor } from '@tinymce/tinymce-react';

import React, { useRef } from 'react';





import "../css/write.css"
import { doc, setDoc, updateDoc } from "firebase/firestore";

import { onAuthStateChanged, getAuth } from "firebase/auth";
function Write() {
  const [editor,setEditor] = useState<any>()

  const [buffer, setBuffer] = useState<any[]>([])
  const [slug, setSlug] = useState('');
  const [title, setTitle] = useState('');
  const [article, setArticle] = useState(`<h1><strong>Course Prelude</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Classes and Lessons</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Why this course is important?</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Who is this course intended for?</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Software Required - Tools - Materials</strong></h1>
  <p>&nbsp;</p>`);
  const limit = 100;

  const sizeLimit = limit ?? 50;
  const [image, setImage] = useState("");
  const [courseName, SetCourseName] = useState("")
  const [youtubeId, setYoutubeId] = useState("")
  const [description, setDescription] = useState("")
  const auth = getAuth(app);
  const [price, setPrice] = useState(1)
  const [videoUrl, setVideoUrl] = useState("")
  const [domain, setDomain] = useState("apespost")
  const [imagesArrayHMTL, setImagesArrayHMTL] = useState(<input id="image1" placeholder='image1'></input>)
  const [skillsLearnAI, setSkillsLearnAI] = useState(1)
  const [imagesA, setImagesA] = useState<string[]>([])
  let db = getFirestore(app)


  const [visibility1, setVisibility1] = useState(true)
  const [visibility2, setVisibility2] = useState(true)
  const [visibility3, setVisibility3] = useState(true)
  const [lang, setLang]= useState("en")

  const [productId, setProductId] = useState("")


  const [title1, setTitle1] = useState("")

  const [courseFor, setCourseFor] = useState("")

  const [instruction1, setInstruction1] = useState("")

  const [key1, setKey1] = useState("")





  function setInitialContent(){
    editor.target.setContent(article)
  }
  
  useEffect(() => {




    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(uid)
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

  }, [])


  function convertToSlug(Text: String) {
    console.log(Text)
    setTitle(Text.toString())
    Text =Text.replace("á", "a")
    Text =Text.replace("é", "e")
    Text =Text.replace("í", "i")
    Text =Text.replace("ó", "o")
    Text =Text.replace("ú", "u")


    return setSlug(Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, ''));
  }

  function Save() {
    console.log("saving")
    console.log("artcicle: " + article)
    saveDocument()

  }
  function handleEditorChange(e: any) {
    console.log('Content was updated:', e.target.getContent());
    setArticle(e.target.getContent());
  }

  async function saveDocument() {

    //get domain

    let domainS = window.location.hostname;
    console.log(domainS)
    setDomain("domains: " + domainS)
    let postsS = "course"
    let metaTagsS = "course_meta_tags"


    //end getDomain

    // set doc
    var tempNamespace = {};
    const str = "course_name" + courseName;
    // Create an initial document to update.
    const postRef = doc(db, postsS, slug);
    await setDoc(postRef, {
      description: article,
      title: title,
      course_name: courseName,
      [str]: true,
      revision: "1.0",
      twitter_image: image,
      lang,
      skills_learn: imagesA,
      videoId: videoUrl,
      requirements: title1,
      courseFor,

      requirements_1:instruction1,
      requirements_2:key1,
      visibility1,
      visibility2,
      visibility3,
      slug,
      productId,

    });
    const metaRef = doc(db, metaTagsS, slug);


    await setDoc(metaRef, {
      OG_DESCRIPTION: description,
      OG_TITLE: title,
      OG_URL: "https://ascendance.dev/3d_model/" + slug + "/",
      OG_IMAGE: image,
      TWITTER_DYNAMIC_TITLE: title
    });



  }

  function saveImageInArray(imgSrc: string, numberOdImgI: number) {
    imagesA[numberOdImgI] = imgSrc
  }

  function addLearnSkillToArray() {
    setSkillsLearnAI(skillsLearnAI + 1)
    buffer.push(<input placeholder={skillsLearnAI + " Skill you Learn"} id={"image" + skillsLearnAI} onChange={e => saveImageInArray(e.target.value, skillsLearnAI - 1)}></input>)

    setBuffer(buffer)

  }
  const handleBeforeAddUndo = (evt:any, editor:any) => {
    const length = editor.getContent({ format: 'text' }).length;
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault();
    }
  };

  return (
    <>
      <div id='write_article_div'>
        <h1>Create Course:</h1>
        <p>Develop an outline for each course that you want to create. This should include the course's objectives, key concepts to cover, and potential resources.</p>
        <input value={title} onChange={e => convertToSlug(e.target.value)} id='title' className='title' type='text' placeholder='Class Title'></input>
        <textarea value={description} onChange={e => setDescription(e.target.value)} id='description' className='description' placeholder='Class Description'></textarea>
        <input id='slug' disabled value={slug} />
        <input id='category' value={courseName} onChange={e => SetCourseName(e.target.value)} placeholder='Course_Name' />
        <input id='price' type='text' value={lang} onChange={e => setLang(e.target.value)} placeholder='lang' />
        <input value={videoUrl} onChange={e => setVideoUrl(e.target.value)} id='version' className='description' placeholder='Video YT ID'></input>

<button onClick={setInitialContent} style={{color:"black"}} >Set Initial Course Skeleton</button>
        <input id='image' placeholder='image source' value={image} onChange={e => setImage(e.target.value)} />
<h1>Description</h1>
        <Editor  
        
  apiKey='kaulizd7eylxst9mi2uitk51uxiiqt4iqwzuze97jnhs4pad'
          init={{
            menubar:false,
            
            plugins: 'wordcount link image code lists',
            toolbar: ' wordcount | numlist bullist | h1 | h2 | p | image | undo redo | bold italic| alignleft aligncenter alignright | code'
          }}
          onChange={e => handleEditorChange(e)}
          onInit={e=>setEditor(e)} />

          <p>Course Content Classes and Nested lessons</p>
        

        <br />
        <div id="imagesArray">

          {buffer.map(item => {
            return item
          })}
          <br></br>
          <input placeholder='productId' onChange={e => { setProductId(e.target.value) }} />
          <br></br>
          <button onClick={addLearnSkillToArray} className='buybutton'>Add What you'll learn +</button>
          <br></br>
          <input placeholder="Requirements" id="title1" onChange={e => setTitle1(e.target.value)}></input>
          <input placeholder="Requirements 1" id="instruction1" onChange={e => setInstruction1(e.target.value)}></input>
          <input placeholder="Requirements 2" id="key1" onChange={e => setKey1(e.target.value)}></input>
          <input type='checkbox' checked={visibility1} id='visibility1' onChange={e => setVisibility1(Boolean(e.target.value))}></input>
          <textarea placeholder="Who is this couse for?" id="description1" onChange={e => setCourseFor(e.target.value)}></textarea>
          <br />




        </div>
        <button id='save' onClick={Save}><span className='buybutton'>Save</span> </button>
      </div>

    </>
  );
}
export default Write;