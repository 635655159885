import {Link} from 'react-router-dom'
import app from '../firebaseapp';
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from 'react'
import '../css/bootstrapmin.css'
import { loadStripe } from '@stripe/stripe-js';
    import { getAuth, onAuthStateChanged, User, signInAnonymously } from "firebase/auth";
import "../css/prompt.css"
import loadingImg from "../img/load.gif"
import { serialize } from 'v8';
import stripeImg from "../img/stripe.png"
let db = getFirestore(app)

function Post() {

  const [activeIndex, setActiveIndex] = useState(null);

  let [sections, setSections] = useState<string[]>([])



  
  const auth = getAuth(app);
  let [prompt1, setPrompt1] = useState("")
let [download1, setDownload1] = useState("Download")

let [data, setData] = useState<any>()

let [courseId, setCourseId] = useState("")

let [title, setTitle] = useState("")
let [promptOwnedB, setPromptOwnedB] = useState("Buy this 3D Asset  to Access to this 3D Model!")
let [promptNumberI, setPromptNumberI] = useState(0)
let [imagesI, setImageI] = useState(0)
let [key1, setKey1] = useState("")
let [price, setPrice] = useState("0")
interface ClassO{
  title:string;
  slug: string;
}
let [classesAO, setClassesAO] = useState<ClassO[]>([]);

  let [description, setDescription] = useState("")
  let [description1, setDescription1] = useState("")


  let [descriptionChanged1, setDescriptionChanged1] = useState("")


let [email, setEmail] = useState("")
let [productId, setProductId] = useState("0")
  
  let [instruction1, setInstruction1] = useState("")


  let [title1, setTitle1] = useState("")


  let [uid, setUid] = useState("")
  let [isAnonymous, setIsAnonymous] = useState(true)
  let [acessToPromptB, setAccessToPromptB] = useState(false)
  let [createdCOB, setCreatedCOB] = useState(false)
  let [dataImages, setDataImages] = useState<string[]>([])
  let [slug, setSlug] = useState("")
  let [image, setImage] = useState("https://www.arweave.net/elAbIMOD74awWYccK7wkGLmT-1O19Hqrq6aULqb5bo8?ext=gif")
  useEffect(() => {

   //read doc /prompt/
   // /3d_model/
   //let countPreUrl = 8
    
   let prompt = window.location.pathname.substring(7, window.location.pathname.length-1)
    setSlug(prompt)
 
   console.log("prompt: "+ prompt)
    const getPrompt = async () => {
     // const data = await getDocs(usersCollectionRef);
     // setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

//get if user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
     setEmail(user.email+"")
     setUid(uid)
     setIsAnonymous(user.isAnonymous)
     getFirestoreDoc(prompt)
     checkIfUserHaveAccess(uid, prompt)
    
    // ...
  } else {
    // User is signed out
    // ...
    getFirestoreDoc(prompt)
  }
});


    };

 getPrompt()

  }, [])

  async function getClasses(){

  }

  async function checkIfUserHaveAccess(uid:string, prompt:string){
    //check in users{uid}
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef)

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  let data = docSnap.data()
  if (data["3D_model_"+prompt] == true){
    setAccessToPromptB(true)
    console.log("has access")
  }else{
    console.log("no access")
  }
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}

  }

  function removeTags(str: string) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

  //get domain
    //required product_id && email


  //end getDomain


  async function getFirestoreDoc(prompt: string){

    const docRef = doc(db, "class", prompt);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  setData(({ ...docSnap.data()}));

  console.log("Document data:", docSnap.data());
  setDescription(docSnap.data().description)
  setTitle(docSnap.data().title)

  setImage(docSnap.data().twitter_image)
  setImageI(parseInt(docSnap.data().skills_learn))
  setTitle1(docSnap.data().requirements)
  setDescription1(docSnap.data().description1)

//setClassesAO(docSnap.data().classes)
//console.log(docSnap.data().classes)

  setDescriptionChanged1(docSnap.data().description1)

  setKey1(docSnap.data().requirements_2)

console.log("eo2:" +docSnap.data().visibility2)



    setProductId(docSnap.data().productId)
  setInstruction1(docSnap.data().instruction1)
  

  // setDataImages(docSnap.data().skills_learn)
  // for (let o=0; o< docSnap.data().skills_learn.length; o++){

  // }

  console.log("size: ")
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}


return
   }
    
  
function downloadModel(){
  //download
console.log(key1)

}

function changeInputKey(e: React.ChangeEvent<HTMLInputElement>){
setDescriptionChanged1(description1.replaceAll(key1,e.target.value))
}


   
function createCheckoutSession(){


  if (productId == "0" ){
return;
  }
  if (uid != "" && !isAnonymous){
    console.log(uid)
    setCreatedCOB(true);
    let domain = window.location.host;
    console.log(domain)
    const stripePromise = loadStripe(
       "pk_live_51Ny2b5BKvAAbP7RKpcgLJ6KBFnx6AqNj4kC8GxPXUtFM8ma8BvIGrA4lXqnx9JgYkgzbW6NjH4GaODXDJXVrqb4M00RKRjRd4Z")
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, productId: productId, uid: uid, slug, price_id: "price_1NyhR0BKvAAbP7RKNvtki6SH" })
  };
  
  fetch('https://ascendance.dev/api/createStripeCheckoutSession/', requestOptions)
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
  
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
  
  console.log(data)
  const stripe = await stripePromise;
  if (stripe!= null){
    stripe.redirectToCheckout({sessionId: data.id})
  
  }
  
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
  
  }else{
    console.log("sign in or sign up")
    setCookie("cart_product_id", productId, 30)
    setCookie("slug", slug,30 )

    window.location.replace("/signup/")
  }
  }

  function setCookie(cname:string, cvalue:any, exdays: any) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  

    return (
      <>        
      <div className="grid-container-1">

      <div className="grid-container-3 bgmaincard">

          <div className="grid-item bgmaincard" >
<div className='card' >
      <img className='card' src={image}/>
      </div>
      </div>
      
  
      <div className="grid-item">
      <h2 className='promptTitle'>{title}</h2>


      <div className={acessToPromptB? "hidden grid-container-2":"grid-container-2"} >
  <div className='grid-item'><p><strong>Secure payment with stripe.</strong></p>
</div>
  <div className='grid-item'><h2><del>59.99</del> US$ {price}</h2></div>
</div>
<div className='grid-container-2'>
  <div className='grid-item'></div>
  <img style={{display:"none"}} className={acessToPromptB? "hidden secureStripe":"secureStripe"} onClick={createCheckoutSession} width="260px" src={stripeImg} />

  <div className='grid-item'></div>
  
  <button style={{display:"none"}} className={acessToPromptB? "hidden buybutton":"buybutton"} onClick={createCheckoutSession} >Buy Now</button>

</div>
<div className="grid-item">
<button className={acessToPromptB? "hidden buybutton":"buybutton"}  >Go to Class</button>
  <br/><br/><hr />
</div>

    </div>
    <div className="grid-item">
    </div>
    </div>
    <div className='grid-container-3'>    
    <div className='grid-container-1'>
    <div className='grid-item'>

        <div className='promptDescription'>
<div className="classDescription" dangerouslySetInnerHTML={{__html: description}}></div>

        </div>

<div className='grid-item'>

<p className={acessToPromptB? "hidden ":""}>{promptOwnedB}</p>
<div className="course_nodes">
  <span className="course_element_title">You'll learn!</span><br/>
  <ul className="learn_list">
 
  

 
      </ul>
  </div>

  <div id="accordion">
    <ul>
  
    <ul>
     
 

    </ul>
    </ul>
    </div>



<div id="signup">

</div>
<hr></hr>
<div className={acessToPromptB? "hidden grid-container-2":"grid-container-2"} >
  <div className='grid-item'><p><strong>Secure payment with stripe.</strong></p>
</div>
  <div className='grid-item'><h2><del>59.99</del> US$ {price}</h2></div>
</div>
<div className='grid-container-2'>
  <div className='grid-item'></div>
  <img style={{display:"none"}} className={acessToPromptB? "hidden secureStripe":"secureStripe"} onClick={createCheckoutSession} width="260px" src={stripeImg} />

  <div className='grid-item'></div>
  <button style={{display:"none"}} className={acessToPromptB? "hidden buybutton":"buybutton"} onClick={createCheckoutSession} >Get Course</button>

</div>
<div className={createdCOB? "":"hidden"} id="loadingCheckOut">
  <img src={loadingImg} width="33px" />Redirecting to secure payment on Stripe</div>

</div>
<div className={acessToPromptB? "grid-item":"grid-item hidden"} id='prompt1' >
<div className='promptcardtitle'>{title1} 
<a href={key1} onClick={downloadModel} className="copyprompt" download>{download1}</a> </div>
<input onChange={(e)=> changeInputKey(e)} className='promptcardinstruction inputKeyPrompt' placeholder={instruction1} /> 

<textarea style={{width:"99%",height:"120px", marginRight:"0px", paddingRight:"-15px"}} disabled value={descriptionChanged1} className='promptcard'></textarea>
</div>


{/*Prompt 2 */}

{/*Prompt 3 */}

</div>
<div className='grid-container-1'>

</div>


        </div>
        <div className='grid-container-1'>
     
           
        
           
            </div>

    </div>
    </div>
      
 
      </>
      
    );
  }
  export default Post;