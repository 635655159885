import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { updateDoc, query, collection, where, getDocs } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/signup.css"
import {doc, getFirestore, serverTimestamp, getDoc} from 'firebase/firestore'

import { getAuth, signOut, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, sendEmailVerification, User } from "firebase/auth";

function SignUp() {

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const [promptBuying, setPromptBuying] = useState("")
const [password, setPassword] = useState("")
const [email, setEmail] = useState("")
const [signActiveB, setSignActiveB] = useState(true)
const [iscreatingB, setIsCreatingB] = useState(false)
const [emailSIn, setEmailSIn] = useState("")
const [passSIn, setPassSIn] = useState("")
const [acceptPrivacyB, setAcceptPrivacyB] = useState(true)
const [acceptTermsB, setAcceptTermsB] = useState(true)
const [isAnonymous, setIsAnonymous] = useState(false)
const [slug, setSlug] = useState("")
const db = getFirestore(app);
  useEffect(() => {
    if (getCookie("cart_product_id") != "" && getCookie("slug")!= ""){
      //read product id
     getPromptWithSlug(getCookie("slug"))
     setSlug(getCookie("slug"))


    }
    async function getPromptWithSlug(slugS:string) {
      const promptRef = doc(db, "prompt",slugS )
      const prompSnap = await getDoc(promptRef)
      if (prompSnap.exists()){
        console.log(prompSnap.data())
        setPromptBuying("You are buying: "+ prompSnap.data().title)
        
      }
 
    }
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        if (user.isAnonymous){
          setIsAnonymous(true)
          console.log("is anonymous")
        }
        console.log(uid)
        if (iscreatingB){
          if (auth.currentUser != null){
            sendEmail(auth.currentUser)
          }
          
        }
        if (!user.isAnonymous && getCookie("slug")!=null && getCookie("slug")!= "" && getCookie("slug").length>3){
          console.log("here: "+ slug)
         // window.location.replace("/"+getCookie(slug)+"/")
        }
        if (!user.isAnonymous){
          window.location.replace('/profile/');

        }
        


        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    
  }, [])
async function createUser(){
  if (isAnonymous){
    logout()
  }
console.log("privacy:"+acceptPrivacyB+", terms:"+acceptTermsB)
if (acceptPrivacyB == false || acceptTermsB == false){
  return;
}
  createUserWithEmailAndPassword(auth, email, password)
.then((userCredential) => {
  // Signed in 
  const user = userCredential.user;
  // SAVE on firestore
  setIsCreatingB(true)

   const userRef = doc(db, "users", user.uid);
   return updateDoc(userRef, {
    ascendance_tokens: 10, 
    updatedAt: serverTimestamp(),
    email: userCredential.user.email
  
  });

 

   

})
.catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  // ..
});

}
async function getPromptSlug(cart_product_id: string){
  const q = query(collection(db, "prompt"), where("productId", "==", cart_product_id));

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    setSlug(doc.data().slug)
    window.location.replace(window.location.host+"/prompt/"+doc.data().slug+"/")
  });
}
function signUser(){
  if (isAnonymous){
    logout()
  }
  signInWithEmailAndPassword(auth, emailSIn, passSIn)
.then((userCredential) => {
  // Signed in 
  const user = userCredential.user;
  console.log(user)
  // ...
})
.catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
});
}
function signUp(){
  setSignActiveB(true)

}
function signIn(){
  setSignActiveB(false)

}
async function sendEmail(user: User){
  if (user != null){
    sendEmailVerification(user)
    .then(() => {
      // Email verification sent!
      // ...
      console.log("sent")
    });
      // ...
  }
}

function logout(){
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}
function getCookie(cname: string) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

    return (
      <>
        <div id="content" className="content">
<div className="grid-container-1">
      <div id='signUp'>
      <div id="signtabs">
        <ul>
<li   onClick={signUp} className={signActiveB? 'signbtn signactive': 'signbtn' }>Sign up</li>
<li onClick={signIn} className={!signActiveB? 'signbtn signactive': 'signbtn' }>Sign in</li>
      
        </ul>
<p id='earn'>Discover a unique marketplace dedicated to AI models like DALL·E, Midjourney, ChatGPT, Stable Diffusion & GPT. Explore high-quality prompts for better results, save on API costs, and even monetize your own creative prompts. Dive into the world of AI and enhance your projects!</p>
<p className='bought'>{promptBuying}</p>
<h1 className= 'signactiveTitle'>{signActiveB? "SIGN UP": "SIGN IN"}</h1>

<div className={signActiveB? ' showsignA': 'showsignI' }>
<input className='inputSign' onChange={e=> setEmail(e.target.value)} type='email' placeholder='email'></input>
<br/>
<input className='inputSign' onChange={e=> setPassword(e.target.value) }value={password} id="password1" type='password' placeholder='password'></input>
<br/>

<input onChange={e=> {setAcceptPrivacyB(e.target.checked)}} checked={acceptPrivacyB} className='checkbox_accept' type='checkbox'></input><a className='text_link' href='/privacy-policy/'>Accept Privacy Policy</a>
<br/>
<input onChange={e=> {setAcceptTermsB(e.target.checked)}} checked={acceptTermsB} className='checkbox_accept' type='checkbox'></input><a className='text_link' href='/terms-and-conditions/'>Accept Terms and Conditions</a>
<br/><br/><br/><br/>
<button className='btnsign' onClick={createUser} >Sign Up</button>
<br></br>
</div>
<div className={!signActiveB? ' showsignA': 'showsignI' }>
<input className='inputSign' type='email' placeholder='email' onChange={e=>setEmailSIn(e.target.value)}></input>
<br/>
<input onChange={e=>setPassSIn(e.target.value)} className='inputSign' id="password2" type='password' placeholder='password'></input>
<br/>
<button className='btnsign' onClick={signUser}>Sign In</button>
<p ><a className='reset' href='/reset-password/'>Reset Password</a></p>
</div>


</div>


      </div>
      </div>  </div>
      
      </>
    );
  }
  export default SignUp;