import React from 'react';
import logo from './logo.svg';
import './App.css';

import Header from './components/Header';
import MainNav from './components/MainNav';
import { Routes, Route, Link } from "react-router-dom";
import SignUp from './components/Signup'
import Profile from './components/Profile'
import Reset from './components/Reset'
import './css/bootstrapmin.css'
import './css/Header.css'
import { useState } from 'react'
import "./css/MainNav.css";
import img_logo from './img/logo.jpg'
import Content from './components/Content'
import Footer from './components/Footer';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {  onSnapshot,doc, getFirestore, getDoc } from 'firebase/firestore';

import app from './firebaseapp'
import { useEffect } from 'react';
import Prompt from "./components/Prompt"
import Model3D from "./components/Model3D"
import Course from "./components/Course"
import Class from "./components/Class"
import WriteLessonAsAdmin from './components/WritetsLessonAsAdmin'


import PrivacyPolicy from './components/Privacy-Policy';
import TermsAndConditions from './components/TermsAndConditions';
import Success from './components/Success'
import WriteAsAdmin from './components/WritePromptsAsAdmin'
import Categories from './components/Categories';
import MidjourneyV5 from "./components/MidjourneyV5";
import Assets3D from "./components/Assets3D";
import WriteLesson from "./components/WriteLesson";
import WriteClass from "./components/WriteClass";
import WriteCourse from "./components/WriteCourse";



import Courses from "./components/Courses";
import Indicators from "./components/Indicators";

import CoursesES from "./components/CoursesES";
import Articles from "./components/Articles";
import Category from "./components/Category";
import Post from "./components/Post";
import Lesson from "./components/Lesson";
import Sentiment from "./components/Sentiment";
import Creature from "./components/Creature";
import Crypto from "./components/Crypto";
import Indicator from "./components/Indicator";




import StableDifussion from "./components/StableDifussion";
import WritePostAsAdmin from "./components/WritetsPostAsAdmin";
import Write3DAsAdmin from "./components/Write3DAssetAsAdmin";
import Whitepaper from "./components/Whitepaper"
import { findAllByTestId } from '@testing-library/react';

function App() {
  let auth = getAuth(app)
  let [navVisible, setNavVisible] = useState(false)
  let [link, setLink] = useState("")
  let [coins, setCoins] = useState("0")
  let [parentDomain, setParentDomain] = useState("https://ascendance.dev")
  let [uid, setUid] = useState("")
  let [slug, setSlug] = useState("")
  let [slugs, setSlugs] = useState<string[]>([])
  
let [loadedB, setLoadB] = useState(false)
  interface classI {
    title: string;
    slug: string;
  }
  interface classIA {
    title: string;
    slug: string;
  }
  
  interface slugStudiedO {
    slug: string;
    studied: boolean;
  }
  
  
  let [slugsStudiedA, setSlugsStudiedA] = useState<slugStudiedO[]>([])

  let [classes, setClasses] = useState<classI[]>([])
  let [classes_0, setClassses_0] = useState<classI[]>([])
  let [classes_1, setClassses_1] = useState<classI[]>([])
  let [classes_2, setClassses_2] = useState<classI[]>([])

let [classeSA, setClasseSA] = useState<classI[]>()
let classesA= ["classes_0", "classes_1", "classes_2"]


  let [isLesson, setIsLesson] = useState(false)
  let [rightButton, setRightButton] = useState("Sign Up/ Sign In")
  let db = getFirestore(app)
  async function getPosts(uid: string){
    let currentpathName = window.location.pathname;

    let isLessonB = false;
    if (currentpathName.startsWith("/l/")){
      isLessonB= true;
    }
    if (true) {
    if (currentpathName.startsWith("/l/")){
      isLessonB= true;
      console.log("pathname: "+ currentpathName)
      // /p/1
      if (slug.endsWith("/")){
        slug = currentpathName.substring(3, currentpathName.length-1)
    
      }else{
        slug = currentpathName.substring(3, currentpathName.length)
    
      }
      console.log("slug :"+slug)
      setSlug(slug)
      const ref = doc(db, "lessons", slug);
const docSnap = await getDoc(ref);
if (docSnap.exists()) {
  // Convert to City object
  const lesson_doc = docSnap.data();
  // Use a City instance method
  console.log(lesson_doc.toString());
  setClasses(lesson_doc.classes)
  setClassses_0(lesson_doc.classes_0)
  setClassses_1(lesson_doc.classes_1)
  setClassses_2(lesson_doc.classes_2)
  let arr:any = []
  let arrO: slugStudiedO[]= []
 if (lesson_doc.classes_0 != null &&
  lesson_doc.classes_1 != null &&
  lesson_doc.classes_2
  ){
  arr.push(lesson_doc.classes_0)
  arr.push(lesson_doc.classes_1)
  arr.push(lesson_doc.classes_2)
  setClasseSA(arr)
  
  for(var o=0; o<lesson_doc["classes_0"].length; o++) {
    arrO.push({slug: lesson_doc["classes_0"][o].slug, studied:false})
    slugs.push(lesson_doc["classes_0"][o].slug)
    
  }
  for(var o=0; o<lesson_doc["classes_1"].length; o++) {
    arrO.push({slug: lesson_doc["classes_1"][o].slug, studied:false})
    slugs.push(lesson_doc["classes_1"][o].slug)

  }
  for(var o=0; o<lesson_doc["classes_2"].length; o++) {
    arrO.push({slug: lesson_doc["classes_2"][o].slug, studied:false})
    slugs.push(lesson_doc["classes_2"][o].slug)

  }

  



 setSlugsStudiedA(arrO)

 }


} else {
  console.log("No such document!");
}

if (uid!= null && uid!=""){
  getCoins(uid, isLessonB)

}
else if (!isLessonB && uid==""){
  return setLoadB(false)
}else{
  return setLoadB(true)
}
    }else{
      if (uid!= null && uid!=""){
        getCoins(uid, isLessonB)
      
      } 
    }
    
  }


   
  
}



async function getCoins(uid: string, isLessonB:boolean) {



    if (uid != ""){

      const unsub = onSnapshot(doc(db, "users", uid), (doc) => {
        console.log("Current data: ", doc.data());
  
        // ...
        let countI = 0
  let thedata = doc.data()
        if (thedata != undefined && thedata != null){
          for (const key in thedata) {

          console.log(`${key}: `);
            if (key.startsWith("coin")){
              countI+=1;
              let cutKey = key.substring(5, key.length)
            for (var ii= 0; ii< slugsStudiedA.length; ii++){
              if (slugsStudiedA[ii].slug== cutKey){
                slugsStudiedA[ii].studied= true
                console.log("true: " +slugsStudiedA[ii].slug+", "+ slugsStudiedA[ii].studied)
                 setSlugsStudiedA(slugsStudiedA)
              }
            }
            }
            
            //console.log("keys: "+slugsStudiedA)
          }
          for (var i=0; i < slugsStudiedA.length;i++){
            console.log(slugsStudiedA[i].studied)
          }
          setCoins(countI+"")
          
        }
     
        
      });
      if (!isLessonB){
        return setLoadB(false)
      }else{
        return setLoadB(true)
      }
      
    }
    

}

  useEffect(() => {
    
    let urlS: string = window.location.href;
    
    if (urlS.includes("/l/")){
      setIsLesson(true)
    }
    function getParentDomainS(){
      if (window.location.hostname == "localhost"){
        setParentDomain("");
      }
    }
    getParentDomainS();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setRightButton("My Account")
        setUid(user.uid)
         getPosts(user.uid)
        
        
           
        
        
      }else{
        console.log("no user");
        getPosts("")

      }
    })

  },[loadedB])
console.log(window.location.hostname)
  
  function goToMidJourney(){
    window.location.href =parentDomain+"/ai-model/midjourney-v5/"
  }
  function goToSentiment(){
    window.location.href =parentDomain+"/indicators/"

  }
  
  function goTo3DModels(){
    window.location.href =parentDomain+"/3D_models/Assets/"
  }
  function goToMyAccount(){
    window.location.href =parentDomain+"/signup/"
  }

  function goToStableDifussion(){
    window.location.href =parentDomain+"/ai-model/Stable-Difussion/"
  }


  function goToArticles(){
    window.location.href ="/articles/"
  }
  function goToCourses(){
    window.location.href ="/courses/"
  }
  function goToWhitepaper(){
    window.location.href =parentDomain+"/whitepaper/"
  }
  
 
  

  function setNavVisibleGo(){
      let isNavB = !navVisible
      setNavVisible(isNavB)

      console.log(isNavB)
      
  }
  function signUp(){
      window.location.replace(parentDomain+"/signup/")
  }
  function goHome(){
    window.location.replace("/")
  }
  return (
    <div className="App">
   <div className={navVisible? "show_main":"hide_main"} id='main'>
    <div className={navVisible? "main_visible": "main_invisible"} id="main_container">
    <header className={navVisible? "header_move": "header_static"} id="header">

<div id="menu_button">
<button className='nav_animate_text' onClick={setNavVisibleGo}>MENU</button>

<div className="icons-menu">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>


                        </div>
</div>
<div  id="brand">
  
<img  onClick={goHome} id='img_logo' src={img_logo}></img>
<p onClick={goHome} id="brand_name">Ascendance.dev</p>
<div id='bt-learn'>
<div id="coins" ><p className='coins'>Your coins: <strong >{coins}</strong></p></div>
<div onClick={goToCourses} className='alert'>Check our FREE classes and Earn 1 coin per one lesson took! 
  <br></br> Soon Learn to Earn!
</div>
  <button onClick={signUp} className='buttonSU'>{rightButton}</button></div>

</div>
<div id="content" className="content">

<Routes>
<Route path="/l/*" element={<Lesson />} /> 
<Route path="/sentiment/s/*" element={<Sentiment />} /> 

<Route path="/creature/c/*" element={<Creature />} /> 
<Route path="/crypto/coin/*" element={<Crypto />} /> 

<Route path="/sentiment_analysis/indicator/*" element={<div><Indicator /><Indicators /></div>} /> 


<Route path="/signup/" element={<SignUp />}/>
<Route path="/profile/" element={<Profile />}/>
<Route path='/reset-password/' element={<Reset />}/>
<Route path='/prompt/*' element={<Prompt />}/>
<Route path='/3d_model/*' element={<Model3D />}/>
<Route path='/course/*' element={<Course />}/>
<Route path='/class/*' element={<Class />}/>

<Route path='/courses/es/' element={<CoursesES />}/>

<Route path='/courses/' element={<Courses />}/>
<Route path='/indicators/' element={<Indicators />}/>


<Route path='/' element={<Content />}/>
<Route path='/terms-and-conditions/' element={<TermsAndConditions />}/>
<Route path='/whitepaper/' element={<Whitepaper />}/>

<Route path='/privacy-policy/' element={<PrivacyPolicy />}/>
<Route path="/success/*" element={<Success />}/>
<Route path="/write-as-admin/" element={<WriteAsAdmin />}/>
<Route path="/write-3D-as-admin/" element={<Write3DAsAdmin />}/>
<Route path="/write-post-as-admin/" element={<WritePostAsAdmin />}/>
<Route path="/write-lesson-as-admin/" element={<WriteLessonAsAdmin />}/>

<Route path="/write-lesson/" element={<WriteLesson />}/>
<Route path="/write-class/" element={<WriteClass />}/>
<Route path="/write-course/" element={<WriteCourse />}/>



<Route path="/categories/" element={<Categories />}/>
<Route path="/ai-model/midjourney-v5/" element={<MidjourneyV5 />}/>

<Route path="/3D_models/Assets/" element={<Assets3D />}/>

<Route path="/ai-model/Stable-Difussion/" element={<StableDifussion />}/>

<Route path="/articles/" element={<Articles />}/>
<Route path='/article/category/*' element={<Category />} />
<Route path='/indicators' element={<div><Indicators /><Courses /></div>}/>


<Route path="/p/*" element={<div><Post /></div>} />



</Routes>
<Footer />
</div>
    </header>

        </div> 


        <nav className={navVisible? "show_nav":"hide_nav"} id="mainNav">
        <div id="sub_menu_top">
<div className="left"><a className='english'>English</a></div>
<button className="right" onClick={setNavVisibleGo}>CLOSE</button>
        </div>
         <ul className={navVisible? "show_nav":"hide_nav"} id="sub_menu">
         <hr className="menuhr"></hr>
         <button id="myAccount" className='linkA' onClick={signUp}><li>{rightButton}</li></button>
            
         <hr className="menuhr"></hr>
            
       


{loadedB
        ? <div><div id="class0">
        <button  className='linkA classTitle'>
        <a className='classTitle' href={"#"}>
         {classes[0].title}
           </a></button>
         <hr className="menuhr"/>
        
         {classes_0.map((item, index) => (
               
               <div key={index}>
               <button  className={item.slug==slug?"linkA lesson": "linkA lesson activeL"}   >
               <a className='lessonA' href={"/l/"+item.slug}>
                {
                
                slugsStudiedA[index].studied? "✅ "+item.title: item.title}
                  </a></button>
                <hr className="menuhr"/>
               
               
                </div>
               
               
                     ))}
        
         </div>
        
         <div id="class1">
        <button  className='linkA classTitle'>
        <a  href={"#"}>
         {classes[1].title}
           </a></button>
         <hr className="menuhr"/>
        
         {classes_1.map((item, index) => (
               
               <div key={index}>
               <button  className={item.slug==slug?"linkA lesson": "linkA lesson activeL"}   >
               <a className='lessonA' href={"/l/"+item.slug}>
            {slugsStudiedA[index+classes_0.length].studied? "✅ "+item.title: item.title}

                  </a></button>
                <hr className="menuhr"/>
               
               
                </div>
               
               
                     ))}
        
         </div>
        
         <div id="class2">
        <button  className='linkA classTitle'>
        <a  href={"#"}>
         {classes[2].title}
           </a></button>
         <hr className="menuhr"/>
        
         {classes_2.map((item, index) => (
               
               <div key={index}>
               <button  className={item.slug==slug?"linkA lesson": "linkA lesson activeL"}   >
               <a className='lessonA' href={"/l/"+item.slug}>
               {slugsStudiedA[index+classes_0.length+classes_1.length].studied? "✅ "+item.title: item.title}

                  </a></button>
                <hr className="menuhr"/>
               
               
                </div>
               
               
                     ))}
        
         </div></div>
        : <div><a href="/">Home</a></div>
      }


      {/* Buttons or inputs to add, update, and remove items */}
            <hr className="menuhr"></hr>

            

            <hr className="menuhr"></hr>

         

 

         </ul>
         <div id="sub_menu_bottom"></div>
         <div id="sub_menu_earn"></div>
        

    </nav>

        <div className={isLesson? "hide_nav": ""} id='nav'>
    <nav className={navVisible? "show_nav":"hide_nav"} id="mainNav">
        <div id="sub_menu_top">
<div className="left"><a className='english'>English</a></div>
<button className="right" onClick={setNavVisibleGo}>CLOSE</button>
        </div>
         <ul className={navVisible? "show_nav":"hide_nav"} id="sub_menu">
         <hr className="menuhr"></hr>
         <button id="myAccount" className='linkA' onClick={signUp}><li>{rightButton}</li></button>
            
         <hr className="menuhr"></hr>
            
            <button className='linkA' onClick={goToCourses} >
           <li>
            Courses
              </li></button>
            <hr className="menuhr"></hr>
            
            <button className='linkA' onClick={goTo3DModels} >
           <li>
            3D Models
              </li></button>
            <hr className="menuhr"></hr>

            <button className='linkA' onClick={goToMidJourney}><li>Midjourney</li></button>

            <hr className="menuhr"></hr>

            <button className='linkA' onClick={goToSentiment}><li>Sentiment Analysis</li></button>

            
            <hr className="menuhr"></hr>
            <button className='linkA' onClick={goToStableDifussion}><li>Stable Diffusion</li></button>
            <hr className="menuhr"></hr>
            <button className='linkA' onClick={goToArticles}><li>Articles</li></button>
            <hr className="menuhr"></hr>

            <button className='linkA' onClick={goToWhitepaper}><li>Whitepaper</li></button>

            <hr className="menuhr"></hr>

            
            <li>ChatGPT 4 (soon)</li>

            <hr className="menuhr"></hr>

         

 

         </ul>
         <div id="sub_menu_bottom"></div>
         <div id="sub_menu_earn"></div>
        

    </nav>
    </div>
    </div>


    </div>

  );
}

export default App;