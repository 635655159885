import {Link} from 'react-router-dom'
import app from '../firebaseapp';
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from 'react'
import '../css/bootstrapmin.css'
import { loadStripe } from '@stripe/stripe-js';
    import { getAuth, onAuthStateChanged, User, signInAnonymously } from "firebase/auth";
import "../css/prompt.css"
import loadingImg from "../img/load.gif"
import { serialize } from 'v8';
import stripeImg from "../img/stripe.png"
let db = getFirestore(app)

function Post() {
  
  const auth = getAuth(app);
  let [prompt1, setPrompt1] = useState("Paint a watercolor technical drawing Black Swan using simple watercolor technique Graded Wash playing with gradient colors using color theory, use Wet On Dry to paint the Black Swan wings, use best techniques for watercolor: Wet on Wet, Dry Brush, uses aesthetics theory of color Scientific Black Swan bird illustration bold lines and beautiful watercolor gradients and distribution")
let [copy1, setCopy1] = useState("Copy")
let [copy2, setCopy2] = useState("Copy")
let [copy3, setCopy3] = useState("Copy")

let [data, setData] = useState<any>()



let [title, setTitle] = useState("")
let [promptOwnedB, setPromptOwnedB] = useState("Buy this Prompt Collection to Access to this Prompts!")
let [promptNumberI, setPromptNumberI] = useState(0)
let [imagesI, setImageI] = useState(0)
let [key1, setKey1] = useState("")
let [key2, setKey2] = useState("")
let [key3, setKey3] = useState("")
let [price, setPrice] = useState("2.99")

  let [description, setDescription] = useState("")
  let [description1, setDescription1] = useState("")
  let [description2, setDescription2] = useState("")
  let [description3, setDescription3] = useState("")


  let [descriptionChanged1, setDescriptionChanged1] = useState("")
  let [descriptionChanged2, setDescriptionChanged2] = useState("")
  let [descriptionChanged3, setDescriptionChanged3] = useState("")


let [email, setEmail] = useState("")
let [product_id, setProductId] = useState("0")
  
  let [instruction1, setInstruction1] = useState("")
  let [instruction2, setInstruction2] = useState("")
  let [instruction3, setInstruction3] = useState("")


  let [title1, setTitle1] = useState("")
  let [title2, setTitle2] = useState("")
  let [title3, setTitle3] = useState("")

  let [visibilityPrompt2B, setVisibilityPrompt2B] = useState(false)
  let [visibilityPrompt3B, setVisibilityPrompt3B] = useState(false)


  let [uid, setUid] = useState("")
  let [isAnonymous, setIsAnonymous] = useState(true)
  let [acessToPromptB, setAccessToPromptB] = useState(false)
  let [createdCOB, setCreatedCOB] = useState(false)
  let [dataImages, setDataImages] = useState<string[]>([])
  let [slug, setSlug] = useState("")
  let [image, setImage] = useState("https://www.arweave.net/elAbIMOD74awWYccK7wkGLmT-1O19Hqrq6aULqb5bo8?ext=gif")
  useEffect(() => {

   //read doc /prompt/
   let countPreUrl = 8
    
   let prompt = window.location.pathname.substring(8, window.location.pathname.length-1)
    setSlug(prompt)
 
   console.log("prompt: "+ prompt)
    const getPrompt = async () => {
     // const data = await getDocs(usersCollectionRef);
     // setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

//get if user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
     setEmail(user.email+"")
     setUid(uid)
     setIsAnonymous(user.isAnonymous)
     getFirestoreDoc(prompt)
     checkIfUserHaveAccess(uid, prompt)
    
    // ...
  } else {
    // User is signed out
    // ...
    getFirestoreDoc(prompt)
  }
});


    };

 getPrompt()

  }, [])

  async function checkIfUserHaveAccess(uid:string, prompt:string){
    //check in users{uid}
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef)

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
  let data = docSnap.data()
  if (data[prompt] == true){
    setAccessToPromptB(true)
    console.log("has access")
  }else{
    console.log("no access")
  }
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}

  }

  function removeTags(str: string) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

  //get domain
    //required product_id && email


  //end getDomain


  async function getFirestoreDoc(prompt: string){

    const docRef = doc(db, "prompt", prompt);
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  setData(({ ...docSnap.data()}));

  console.log("Document data:", docSnap.data());
  setDescription(docSnap.data().description)
  setTitle(docSnap.data().title)

  setImage(docSnap.data().twitter_image)
  setImageI(parseInt(docSnap.data().images))
  setTitle1(docSnap.data().title1)
  setTitle2(docSnap.data().title2)
  setTitle3(docSnap.data().title3)

  setDescription1(docSnap.data().description1)
  setDescription2(docSnap.data().description2)
  setDescription3(docSnap.data().description3)



  setDescriptionChanged1(docSnap.data().description1)
  setDescriptionChanged2(docSnap.data().description2)
  setDescriptionChanged3(docSnap.data().description3)

  setKey1(docSnap.data().key1)
  setKey2(docSnap.data().key2)
  setKey3(docSnap.data().key3)

  setVisibilityPrompt3B(docSnap.data().visibility3);
setVisibilityPrompt2B(docSnap.data().visibility2);
console.log("eo2:" +docSnap.data().visibility2)
if (((description2 && title2) != null) && (description2 && title2) != ""){

}

if (((description3 && title3) != null) && (description3 && title3) != ""){
  console.log("p3 visible")
}


    setProductId(docSnap.data().productId)
  setInstruction1(docSnap.data().instruction1)
  setInstruction2(docSnap.data().instruction2)
  setInstruction3(docSnap.data().instruction3)


  setDataImages(docSnap.data().imagesA)
  for (let o=0; o< docSnap.data().imagesA.length; o++){

  }

  console.log("size: "+dataImages.length)
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}


return
   }
    
  
function copyText(){
    navigator.clipboard.writeText(descriptionChanged1)
    setCopy1("Copied!")
}
function copyText2(){
  navigator.clipboard.writeText(descriptionChanged2)
  setCopy2("Copied!")
}
function copyText3(){
  navigator.clipboard.writeText(descriptionChanged3)
  setCopy3("Copied!")
}
function changeInputKey(e: React.ChangeEvent<HTMLInputElement>){
setDescriptionChanged1(description1.replaceAll(key1,e.target.value))
}
function changeInputKey2(e: React.ChangeEvent<HTMLInputElement>){
  setDescriptionChanged2(description2.replaceAll(key2,e.target.value))
  }
  function changeInputKey3(e: React.ChangeEvent<HTMLInputElement>){
    setDescriptionChanged3(description3.replaceAll(key3,e.target.value))
    }

   
function createCheckoutSession(){


  if (product_id == "0" ){
return;
  }
  if (uid != "" && !isAnonymous){
    console.log(uid)
    setCreatedCOB(true);
    let domain = window.location.host;
    console.log(domain)
    const stripePromise = loadStripe(
       "pk_live_51Ny2b5BKvAAbP7RKpcgLJ6KBFnx6AqNj4kC8GxPXUtFM8ma8BvIGrA4lXqnx9JgYkgzbW6NjH4GaODXDJXVrqb4M00RKRjRd4Z")
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, productId: product_id, uid: uid, slug, price_id: "price_1Ny7VoBKvAAbP7RKX1Bfdl0p"})
  };
  
  fetch('https://ascendance.dev/api/createStripeCheckoutSession/', requestOptions)
      .then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
  
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
  
  console.log(data)
  const stripe = await stripePromise;
  if (stripe!= null){
    stripe.redirectToCheckout({sessionId: data.id})
  
  }
  
      })
      .catch(error => {
          //this.setState({ errorMessage: error.toString() });
          console.error('There was an error!', error);
      });
  
  }else{
    console.log("sign in or sign up")
    setCookie("cart_product_id", product_id, 30)
    setCookie("slug", slug,30 )

    window.location.replace("/signup/")
  }
  }

  function setCookie(cname:string, cvalue:any, exdays: any) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  

    return (
      <>        
      <div className="grid-container-1">

      <div className="grid-container-3 bgmaincard">

          <div className="grid-item bgmaincard" >
<div className='card' >
      <img className='card' src={image}/>
      </div>
      </div>
      
  
      <div className="grid-item">
      <h2 className='promptTitle'>{title}</h2>

    </div>
    <div className="grid-item">
    </div>
    </div>
    <div className='grid-container-3'>    
    <div className='grid-container-1'>
    <div className='grid-item'>

        <div className='promptDescription'>
<div dangerouslySetInnerHTML={{__html: description}}></div>

        </div>

<div className='grid-item'>

<p className={acessToPromptB? "hidden ":""}>{promptOwnedB}</p>

<div id="signup">

</div>
<hr></hr>
<div className={acessToPromptB? "hidden grid-container-2":"grid-container-2"} >
  <div className='grid-item'><p><strong>Secure payment with stripe.</strong></p>
</div>
  <div className='grid-item'><h2>US$ {price}</h2></div>
</div>
<div className='grid-container-2'>
  <div className='grid-item'></div>
  <img className={acessToPromptB? "hidden secureStripe":"secureStripe"} onClick={createCheckoutSession} width="260px" src={stripeImg} />

  <div className='grid-item'></div>
  <button className={acessToPromptB? "hidden buybutton":"buybutton"} onClick={createCheckoutSession} >Get Prompt</button>

</div>
<div className={createdCOB? "":"hidden"} id="loadingCheckOut">
  <img src={loadingImg} width="33px" />Redirecting to secure payment on Stripe</div>

</div>
<div className={acessToPromptB? "grid-item":"grid-item hidden"} id='prompt1' >
<div className='promptcardtitle'>{title1} 
<button onClick={copyText} className="copyprompt">{copy1}</button> </div>
<input onChange={(e)=> changeInputKey(e)} className='promptcardinstruction inputKeyPrompt' placeholder={instruction1} /> 

<textarea style={{width:"99%",height:"120px", marginRight:"0px", paddingRight:"-15px"}} disabled value={descriptionChanged1} className='promptcard'></textarea>
</div>


{/*Prompt 2 */}
<div className={!acessToPromptB? "hidden ":""}>
<div className={visibilityPrompt2B? "grid-item":"grid-item hidden"} id='prompt2' >
<div className='promptcardtitle'>{title2} 
<button onClick={copyText2} className="copyprompt">{copy2}</button> </div>
<input onChange={(e)=> changeInputKey2(e)} className='promptcardinstruction inputKeyPrompt' placeholder={instruction2} /> 

<textarea style={{width:"99%",height:"120px", marginRight:"0px", paddingRight:"-15px"}} disabled value={descriptionChanged2} className='promptcard'></textarea>
</div>
</div>
{/*Prompt 3 */}
<div className={!acessToPromptB? "hidden ":""}>

<div className={visibilityPrompt3B? "grid-item":"grid-item hidden"} id='prompt3' >
<div className='promptcardtitle'>{title3} 
<button onClick={copyText3} className="copyprompt">{copy3}</button> </div>
<input onChange={(e)=> changeInputKey3(e)} className='promptcardinstruction inputKeyPrompt' placeholder={instruction3} /> 

<textarea style={{width:"99%",height:"120px", marginRight:"0px", paddingRight:"-15px"}} disabled value={descriptionChanged3} className='promptcard'></textarea>
</div>
</div>
</div>
<div className='grid-container-1'>

</div>


        </div>
        <div className='grid-container-1'>
        {dataImages.map((img, numI) => (
        <div key={"numI"+dataImages.indexOf(img)} className='grid-item'>
        <img className='card' src={img}/>

        </div>
      ))}
           
        
           
            </div>

    </div>
    </div>
      
 
      </>
      
    );
  }
  export default Post;