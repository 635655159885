import "../css/Content.css"
import midjourney from "../img/midjourney.png"
import chatgpt from "../img/chatgpt.jpg"
import chatgpt4 from "../img/chatgpt4.jpg"

function PrivacyPolicy() {
    return (<>
        <div className="grid-container-1">
        <div>
<div id="privacy_policy">
<div>
<div>
<p><strong>Privacy Policy for Ascendance Dev, Inc.</strong></p>
<p><em>Last Updated: May 15, 2023</em></p>
<p>Welcome to Ascendance Dev, Inc.. Your privacy is important to us. This Privacy Policy outlines the types of information that are collected and received by Ascendance Dev, Inc., how it is used, and how it is kept secure. This policy also explains your rights regarding your personal data.</p>
<p><strong>1. Information We Collect</strong></p>
<p>1.1.&nbsp;<strong>Cookies</strong>: Ascendance Dev, Inc. uses cookies to improve the user experience, personalize content, and analyze website traffic. Cookies are small files stored on your device. You can choose to disable cookies through your browser settings, but this may affect your ability to use some features of our website.</p>
<p>1.2.&nbsp;<strong>Personal Data</strong>: We may collect personal information that you voluntarily provide, such as your name and email address when you subscribe to our newsletter or contact us.</p>
<p><strong>2. Use of Your Information</strong></p>
<p>2.1. We use the information we collect to provide, maintain, and improve our services, as well as to display ads that are relevant to you.</p>
<p>2.2. We may also use your information to respond to your inquiries, send you our newsletter if you have subscribed, or for other purposes with your consent.</p>
<p><strong>3. Information Sharing</strong></p>
<p>3.1. We do not sell your personal information to third parties. We may, however, share anonymized aggregate data with our advertising partners to allow them to serve you with relevant advertisements.</p>
<p>3.2. We may disclose your information if required by law or in the event of a business transfer.</p>
<p><strong>4. Data Security</strong></p>
<p>4.1. We implement appropriate security measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction.</p>
<p>4.2. Despite our best efforts, no method of transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee its absolute security.</p>
<p><strong>5. Your Rights</strong></p>
<p>5.1. You have the right to access, correct, or delete your personal data. You also have the right to object to or restrict our processing of your data.</p>
<p>5.2. If you wish to exercise any of these rights, please contact us at the email address provided below.</p>
<p><strong>6. Changes to This Privacy Policy</strong></p>
<p>6.1. We may update this Privacy Policy from time to time. The date at the top of this Privacy Policy indicates when it was last updated.</p>
<p><strong>7. Contact Us</strong></p>
<p>If you have any questions or concerns about this Privacy Policy, please contact us at: contact@ascendance.dev</p>
<p>By using our website, you consent to our Privacy Policy. If you do not agree with this policy, please do not use our site.</p>
</div>
</div>
</div>
</div>

            
            </div>
           
        
    </>  );
}

export default PrivacyPolicy;