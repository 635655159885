import {Link} from 'react-router-dom'
  import {useEffect, useState} from 'react'
  import { collection, query, where, getDocs, DocumentData, getDoc, getFirestore } from "firebase/firestore";
  import app from "../firebaseapp"
  import { Editor } from '@tinymce/tinymce-react';
  import "../css/write.css"
  import { doc, setDoc, updateDoc } from "firebase/firestore"; 
  import React, { useRef } from 'react';

  import {onAuthStateChanged, getAuth  } from "firebase/auth";
  function Write() {
let db = getFirestore(app)
    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');
    const [article, setArticle] = useState('');
    const [image, setImage] = useState("");
    const [category, setCategory] = useState("")
    const [youtubeId, setYoutubeId] = useState("")
    const [description, setDescription] = useState("")
    const auth = getAuth(app);
    const [amazonProduct, setAmazonProduct] = useState("")
    const [loadSlugS, setLoadSlugS] = useState("")
    const [amazonProductDescription, setAmazonProductDescription] = useState("")
    const [domain, setDomain] = useState("apespost")
    const [editor,setEditor] = useState<any>()
    let [gpt, setGpt] = useState("")
    useEffect(() => {



      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          console.log(uid)
          // ...
        } else {
          // User is signed out
          // ...
        }
      });
      
    }, [])


    function convertToSlug(Text: String) {
      console.log(Text)
      setTitle(Text.toString())
      return setSlug(Text.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, ''));
    }
        
function Save(){
  console.log("saving")
  console.log("artcicle: "+ article)
  saveDocument()

}
function handleEditorChange(e:any ){
  console.log('Content was updated:', e.target.getContent());
  setArticle( e.target.getContent());
}
async function saveDocument(){

      //get domain

      let domainS = window.location.hostname;
      console.log(domainS)
      setDomain("domains: "+domainS)
      let postsS = "unknown"
      let metaTagsS = "unknown"
      switch(domainS){
        case "apesascendance.com":
          postsS = "apeslessons"
          metaTagsS ="apes_lessons_meta_tags"
          break;
          case "ascendance.dev":
            metaTagsS= "lessons_meta_tags"
            postsS = "lessons"
            break;
          case "localhost":
            console.log("EUUUUUU")
            postsS = "lessons"
            metaTagsS="lessons_meta_tags"
      
          break;
      }
    
      //end getDomain


      
  //check if doc doesn't exist

  // set doc
  var tempNamespace = {};
  const str = "category_"+category;
  // Create an initial document to update.
const postRef = doc(db, postsS, slug);
await setDoc(postRef, {
    description: article,
    title: title,
    [str] : true,
    revision: "1.0",
    image: image,
    youtubeId:youtubeId,
    codeString: amazonProduct,
    gpt: amazonProductDescription 
});
const metaRef = doc(db, metaTagsS, slug);

await setDoc(metaRef, {
  OG_DESCRIPTION: description,
  OG_TITLE: title,
  OG_URL: "https://ascendance.dev/l/"+slug+"/",
  OG_IMAGE: image,
  TWITTER_DYNAMIC_TITLE: title
});



}

async function loadArticle(){
  const postRef = doc(db, "lessons", loadSlugS);

  const docSnap = await getDoc(postRef)
if (docSnap.exists()){
const postData = docSnap.data();

setTitle(postData.title)
setArticle(postData.description)
editor.target.setContent(postData.description)
setCategory(postData.category+"")
setYoutubeId(postData.youtubeId)
setImage(postData.image)
setAmazonProduct(postData.amazon)
setAmazonProductDescription(postData.amazon_description)

for (const [key, value] of Object.entries(postData)) {
  console.log(`${key}: ${value}`);
  if (key.includes("category_")){
    setCategory(key.substring(9,key.length) )

  }
}

//
const meta_tagsRef = doc(db, "lessons_meta_tags", loadSlugS)
const docSnap2 = await getDoc(meta_tagsRef)
if (docSnap2.exists()){
  setDescription(docSnap2.data().OG_DESCRIPTION)

}




}else{
  console.log("document doesn't exist")
}
}

      return (
        <>
        <div id='write_article_div'>
  <h1>Write an article:</h1>
  <input value={title} onChange={e => convertToSlug(e.target.value)} id='title' className='title' type='text' placeholder='Article Title'></input>
  <textarea value={description} onChange={e=> setDescription(e.target.value)} id='description' className='description'  placeholder='Article Description'></textarea>
  <input id='slug' disabled value={slug} />
  <input id='category' value={category} onChange={e => setCategory(e.target.value)} placeholder='category'/>
  <textarea id='amazon' value={amazonProduct} onChange={e => setAmazonProduct(e.target.value)} placeholder='code'/>
  <textarea value={amazonProductDescription} onChange={e=> setAmazonProductDescription(e.target.value)} id='gpt' className='description'  placeholder='GPT'></textarea>

  <input id='youtubeId' value={youtubeId} onChange={e => setYoutubeId(e.target.value)} placeholder='youtubeId'/>

  <input id='image' placeholder='image source' value={image} onChange={e=> setImage(e.target.value)} />

  <Editor apiKey='kaulizd7eylxst9mi2uitk51uxiiqt4iqwzuze97jnhs4pad' 
     init={{ plugins: 'link image code',
     toolbar: 'ul | li | h1 | h2 | p | undo redo | bold italic| alignleft aligncenter alignright | code'}}
     onChange={e => handleEditorChange(e)}  
     onInit={e=>setEditor(e)}    />
<button id='save' onClick={Save}><span className='pinText'>Save</span> </button>
        </div>
        
        <input value={loadSlugS} onChange={e=>{setLoadSlugS(e.target.value)}} placeholder='Load Slug'></input>
        <button onClick={loadArticle}>Load Article</button>
        
        </>
      );
    }
    export default Write;