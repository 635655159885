import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where, getDoc, DocumentData, doc, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/lectures.css"
function Categories() {

    let [slug, setSlug] = useState("")
    let [load, setLoad] = useState(false)
    let [posts, setPosts] = useState<any>([])
    let [listItems, setListItems] = useState<any[]>([])
    let [categories, setCategories] = useState<string[]>([])
    let [domain, setDomain] = useState("unknown")
    let [lectures, setLectures] = useState("lectures")
    
    async function getDomain(){
      console.log("UUU")

     }

    useEffect(() => {
      let domainS = window.location.hostname;
      console.log(domainS)
      setDomain("domains: "+domainS)
      let lectureS = "unknown"
      switch(domainS){
        case "apesascendance.com":
          setLectures("lecturesApes");
          lectureS = "lecturesApes"
          break;
          case "ascendance.dev":
            setLectures("lectures");
            lectureS = "lectures"
            break;
          case "localhost":
            console.log("EUUUUUU")
            setLectures("lectures");
            lectureS = "lectures"

          break;
      }
      
      
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /category/1
        slug = currentpathName.substring(8, currentpathName.lastIndexOf("/"))
        setSlug(slug)
        const getPosts = async () => {
        
        let db = getFirestore(app)
          const docRef = doc(db, lectureS, "list");
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setPosts(docSnap.data());
            setCategories(docSnap.data()["categories"])

          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
         

            return posts


      
            

                    }
        if (!load){
            getPosts()
load = true
setLoad(load)
        }else{
          console.log("loaded")
        }
      


       

    

    }, [])

      

  

    return (
      <>
       <div>
<h1 id="art_categories">Article Categories: </h1>

<div className="grid-container-2">
        {categories.map(d => (<div className="grid-item"><div className="card"><a href={"/article/category/"+d+"/" } ><img width="100%" src="https://arweave.net/BzpXL1PMrlod89cS32LJ9bd-T7BHlJGMudw3vANxJHA" alt="Avatar"/><div className="container title_course"><div className="topContainer"><span className="students">Articles <b></b></span><span className="time"> </span></div><h4><b>{d}</b></h4><p>Ascendance.dev</p></div></a></div></div>))} 
            

        </div>

       </div>
       
      </>
    );
  }
  export default Categories;
