import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where, getDocs, DocumentData } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/reset.css"
import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";

function Reset() {
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const [email, setEmail] = useState("")
const [iscreatingB, setIsCreatingB] = useState(false)
const [info, setInfo] = useState("")
const [sentB, setSentB] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;

     
        window.location.replace('/profile/');

        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    
  }, [])

function resetPassword(){
    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
      setInfo("Email sent if this email is registred, check your inbox!")
      setSentB(true)
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
}



    return (
      <>
      <div id='reset'>
<h2 className='h2title'>Reset Email Password</h2>
      <div >
        <br></br>
<input className='inputSign' type='email' placeholder='email' onChange={e=>setEmail(e.target.value)}></input>
<br/>
<br />
<button disabled={sentB} className='btnreset' onClick={resetPassword}>Reset Password</button>
<p className='info'>{info}</p>
<br/><br/><br/>
</div>

      </div>
      
      </>
    );
  }
  export default Reset;