import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where, getDoc, DocumentData, doc, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/categories.css"
const db =getFirestore(app)
function Categories() {

    let [slug, setSlug] = useState("")
    let [load, setLoad] = useState(false)
    let [posts, setPosts] = useState<any>([])
    let [listItems, setListItems] = useState<any[]>([])
    let [categories, setCategories] = useState<string[]>([])
    let [domain, setDomain] = useState("unknown")
    let [lectures, setLectures] = useState("lectures")
    
    async function getDomain(){
      console.log("UUU")

     }

    useEffect(() => {
      let domainS = window.location.hostname;
      console.log(domainS)
      setDomain(domainS)
      setLectures("categories");

     
      
      
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /category/1
        slug = currentpathName.substring(8, currentpathName.lastIndexOf("/"))
        setSlug(slug)

        const getPosts = async () => {
        
        
          const docRef = doc(db, "categories", "list");
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setPosts(docSnap.data());
            setCategories(docSnap.data()["categories"])

          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
         

            return posts


      
            

                    }
        if (!load){
            getPosts()
load = true
setLoad(load)
        }else{
          console.log("loaded")
        }
      


       

    

    }, [])

      
    function convertToSlug(Text: String) {
        console.log(Text)
        return Text.toLowerCase()
                  .replace(/ /g, '-')
                  .replace(/[^\w-]+/g, '')
      }
  

    return (
      <>
       <div>
<h1 className="title">Categories: </h1>

<div className="grid-container-2">
        {categories.map(d => 
            (
            <div className="grid-item">
                <div className="card">
                    <a href={"/ai-model/"+convertToSlug(d)+"/" } >
                        <img width="100%" src="https://arweave.net/BzpXL1PMrlod89cS32LJ9bd-T7BHlJGMudw3vANxJHA" alt="Avatar"/>
                        <div className="container title_course"><div className="topContainer">
                            <span className="students">{d} <b></b></span><span className="time"> 
                            </span></div><h4><b>{d}</b></h4><p>ascendance.dev</p></div></a></div></div>))} 
            

        </div>

       </div>
       
      </>
    );
  }
  export default Categories;