import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { collection, query, where, getDocs, DocumentData, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import { Editor } from '@tinymce/tinymce-react';

import React, { useRef } from 'react';





import "../css/write.css"
import { doc, setDoc, updateDoc } from "firebase/firestore";

import { onAuthStateChanged, getAuth } from "firebase/auth";
function Write() {

  const [buffer, setBuffer] = useState<any[]>([])
  const [slug, setSlug] = useState('');
  const [title, setTitle] = useState('');
  const [article, setArticle] = useState(`<h1><strong>Class Prelude</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Concepts and Lessons</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Why this class is important?</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Who is this class intended for?</strong></h1>
  <p>&nbsp;</p>
  <h1><strong>Software Required - Tools - Materials</strong></h1>
  <p>&nbsp;</p>`);
  const [image, setImage] = useState("");
  const [courseId, SetCourseId] = useState("")
  const [youtubeId, setYoutubeId] = useState("")
  const [description, setDescription] = useState("")
  const auth = getAuth(app);
  const [price, setPrice] = useState(1)
  const [videoUrl, setVideoUrl] = useState("")
  const [domain, setDomain] = useState("apespost")
  const [imagesArrayHMTL, setImagesArrayHMTL] = useState(<input id="image1" placeholder='image1'></input>)
  const [imagesAI, setImagesAI] = useState(1)
  const [imagesA, setImagesA] = useState<string[]>([])
  let db = getFirestore(app)


  const [visibility1, setVisibility1] = useState(true)
  const [visibility2, setVisibility2] = useState(true)
  const [visibility3, setVisibility3] = useState(true)


  const [productId, setProductId] = useState("")


  const [title1, setTitle1] = useState("")

  const [description1, setDescription1] = useState("")

  const [instruction1, setInstruction1] = useState("")

  const [key1, setKey1] = useState("")






  useEffect(() => {

function setInitialContent(){
  
}

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(uid)
        // ...
      } else {
        // User is signed out
        // ...
      }
    });

  }, [])


  function convertToSlug(Text: String) {
    console.log(Text)
    setTitle(Text.toString())
    Text =Text.replace("á", "a")
    Text =Text.replace("é", "e")
    Text =Text.replace("í", "i")
    Text =Text.replace("ó", "o")
    Text =Text.replace("ú", "u")
    return setSlug(Text.toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, ''));
  }

  function Save() {
    console.log("saving")
    console.log("artcicle: " + article)
    saveDocument()

  }
  function handleEditorChange(e: any) {
    console.log('Content was updated:', e.target.getContent());
    setArticle(e.target.getContent());
  }

  async function saveDocument() {

    //get domain

    let domainS = window.location.hostname;
    console.log(domainS)
    setDomain("domains: " + domainS)
    let postsS = "class"
    let metaTagsS = "class_3D_meta_tags"


    //end getDomain

    // set doc
    var tempNamespace = {};
    const str = "category_" + courseId;
    // Create an initial document to update.
    const postRef = doc(db, postsS, slug);
    console.log("sl:"+ slug )
    let objO= {
      description: article,
      title: title,
      courseId: courseId,
      [str]: true,
      revision: "1.0",
      twitter_image: image,
      price: price,
      concepts: imagesA,
      videoId: videoUrl,
      title1,
      requirement_1: description1,

      requirement_2: instruction1,
      requirement_3: key1,
      visibility1,
      visibility2,
      visibility3,
      slug,
      productId,

    }
    console.log(objO)
    await setDoc(postRef, objO);
    const metaRef = doc(db, metaTagsS, slug);
    console.log("description:"+description)
    console.log("title:"+title)
    console.log("image"+image)
    console.log("slug: "+slug)
    await setDoc(metaRef, {
      OG_DESCRIPTION: description,
      OG_TITLE: title,
      OG_URL: "https://ascendance.dev/3d_model/" + slug + "/",
      OG_IMAGE: image,
      TWITTER_DYNAMIC_TITLE: title
    });



  }

  function saveImageInArray(imgSrc: string, numberOdImgI: number) {
    imagesA[numberOdImgI] = imgSrc
  }

  function addImageToArray() {
    setImagesAI(imagesAI + 1)
    buffer.push(<input placeholder={imagesAI + " Concept"} id={"image" + imagesAI} onChange={e => saveImageInArray(e.target.value, imagesAI - 1)}></input>)

    setBuffer(buffer)

  }


  return (
    <>
      <div id='write_article_div'>
        <h1>Create Class:</h1>
        <p>Develop an outline for each course that you want to create. This should include the course's objectives, key concepts to cover, and potential resources.</p>
        <input value={title} onChange={e => convertToSlug(e.target.value)} id='title' className='title' type='text' placeholder='Class Title'></input>
        <textarea value={description} onChange={e => setDescription(e.target.value)} id='description' className='description' placeholder='Class Description'></textarea>
        <input id='slug' disabled value={slug} />
        <input id='category' value={courseId} onChange={e => SetCourseId(e.target.value)} placeholder='CourseId' />
        <input id='price' type='number' value={price} onChange={e => setPrice(parseFloat(e.target.value))} placeholder='price' />
        <input value={videoUrl} onChange={e => setVideoUrl(e.target.value)} id='version' className='description' placeholder='Video URL'></input>


        <input id='image' placeholder='image source' value={image} onChange={e => setImage(e.target.value)} />

        <Editor  

apiKey='kaulizd7eylxst9mi2uitk51uxiiqt4iqwzuze97jnhs4pad'
          init={{
            menubar:false,
            
            plugins: 'link image code',
            toolbar: 'h1 | h2 | p | image | undo redo | bold italic| alignleft aligncenter alignright | code'
          }}
          initialValue={article}
          
          onChange={e => handleEditorChange(e)} />
             

        <br />
        <div id="imagesArray">

          {buffer.map(item => {
            return item
          })}
          <br></br>
          <input placeholder='productId' onChange={e => { setProductId(e.target.value) }} />
          <br></br>
          <button onClick={addImageToArray} className='buybutton'>Add Concepts +</button>
          <br></br>
          <input placeholder="Requirement 1" id="title1" onChange={e => setTitle1(e.target.value)}></input>
          <input placeholder="Requirement 2" id="instruction1" onChange={e => setInstruction1(e.target.value)}></input>
          <input placeholder="Requirement 3" id="key1" onChange={e => setKey1(e.target.value)}></input>
          <input type='checkbox' checked={visibility1} id='visibility1' onChange={e => setVisibility1(Boolean(e.target.value))}></input>
          <textarea placeholder="description 1" id="description1" onChange={e => setDescription1(e.target.value)}></textarea>
          <br />




        </div>
        <button id='save' onClick={Save}><span className='buybutton'>Save</span> </button>
      </div>

    </>
  );
}
export default Write;