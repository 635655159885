import React, { useEffect, useState } from 'react';
import '../css/TopArticles.css';

interface Article {
  title: string;
  url: string;
  description: string;
  image: string;
}

const TopArticles: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(
          'https://pink-neat-turtle-227.mypinata.cloud/ipfs/QmP5Qg3HTDdr4LVaZa1i6HKsyWHQnwu6ddH3ignpyhGV8V'
        );
        const data: Article[] = await response.json();
        setArticles(data.slice(0, 3)); // Only take the top 3 articles
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="articles-container">
      {articles.map((article, index) => (
        <div key={index} className="article-card">
           <a href={article.url} className="article-link" target="_blank" rel="noopener noreferrer"><img src={article.image} alt={article.title} className="article-image" />
          <div className="article-content">
            <h2 className="article-title">{article.title}</h2>
            <p className="article-description">{article.description}</p>
           
              Read more
         
          </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default TopArticles;
