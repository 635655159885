import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where, getDocs, DocumentData, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import "../css/category.css"

function Category() {
  const db = getFirestore(app)

    let [slug, setSlug] = useState("")
    let [load, setLoad] = useState(false)
    let [posts, setPosts] = useState<any[]>([])
    let [listItems, setListItems] = useState<any[]>([])
    let [domain, setDomain] = useState("localhost")
    

    useEffect(() => {

//get domain

let domainS = window.location.hostname;
console.log(domainS)
setDomain("domains: "+domainS)
let postsS = "unknown"
switch(domainS){
  case "apesascendance.com":
    postsS = "apesposts"
    break;
    case "ascendance.dev":
      postsS = "posts"
      break;
    case "localhost":
      console.log("EUUUUUU")
      postsS = "posts"

    break;
}



//end getDomain


        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /category/1
        slug = currentpathName.substring(18, currentpathName.lastIndexOf("/"))
        console.log(slug)
        setSlug(slug)
        const getPosts = async () => {
            const q = query(collection(db, postsS), where("category_"+slug.toString(), "==", true));
            posts = []
            setPosts(posts)
            listItems = []
            setListItems(listItems)
            const querySnapshot = await getDocs(q);
            // querySnapshot.forEach((doc) => {
            //   let theDoc:DocumentData = doc.data()
            //   // doc.data() is never undefined for query doc snapshots
            //   console.log(doc.id, " => ", doc.data());
            //   theDoc.id = doc.id
            //   theDoc.slug = doc.id
            //   posts.push(theDoc)
            //   setPosts(posts)



              

            // });
            setPosts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

            return posts


      
            

                    }
        if (!load){
            getPosts()
load = true
setLoad(load)
        }else{
          console.log("loaded")
        }
      


       

    

    }, [])

      

  

    return (
      <>
       <div>
<h1 id="category">Category: {slug}</h1>
<br /><br /><br /><br /><br />
<ul> 
        {posts.map(d => (<li ><a className='listALi' href={"/p/"+d.id+"/" } key={d.id}> {d.title}<br /></a></li>))} 
                </ul>



       </div>
       
      </>
    );
  }
  export default Category;
