import {Link} from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection, doc, getDoc} from "firebase/firestore";
import {useEffect, useState} from 'react'
import { getAuth, onAuthStateChanged, User, signInAnonymously } from "firebase/auth";

import { left } from '@popperjs/core';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import Category from './Category';
import "../css/crypto.css"
import app from "../firebaseapp"

function Post() {
  let [title, setTitle] = useState("")
  let [negativeI, setNegativeI] = useState(0)
  let [positiveI, setPositiveI] = useState(0)
  let [neutralI, setNeutralI] = useState(0)
  let [lastDoc, setLastDoc] = useState("")
  let [positiveA, setPositiveA] = useState<[string, number][]>([]);
  let [negativeA, setNegativeA] = useState<[string, number][]>([]);
  let [neutralA, setNeutralA] = useState<[string, number][]>([]);


  let [labelsA, setLabelsA] = useState<[string, number][]>([]);

  let [categoriesA, setCategoriesA] = useState<[string, number][]>([]);

  
  interface IFrequencyMap {
    [key: string]: number;
  }
  //  const [frequencyMap, setFrequencyMap] = useState<IFrequencyMap>({});
  
  let [freqPos, setFreqPos] = useState<IFrequencyMap>({})
  let [freqNeg, setFreqNeg] = useState<IFrequencyMap>({})
  let [freqNeu, setFreqNeu] = useState<IFrequencyMap>({})

  let [freqLab, setFreqLab] = useState<IFrequencyMap>({})
  let [freqCat, setFreqCat] = useState<IFrequencyMap>({})

  
  let [totalPosSlice, setTotalPosSlice] = useState(0)
  let [totalNegSlice, setTotalNegSlice] = useState(0)
  let [totalNeuSlice, setTotalNeuSlice] = useState(0)
  
  let [totalLabSlice, setTotalLabSlice] = useState(0)
  let [totalCatSlice, setTotalCatSlice] = useState(0)



let [totalK, setTotalK] = useState(0)
let [fag_last_updated, setFag_Last_Updated]= useState("")



type PieStyle = {
  transform: string;
  backgroundColor: string;
};

// Default style values
const defaultPieStyle: PieStyle = {
  transform: 'rotate(0deg)',
  backgroundColor: 'green'
};
let [pieStyle1, setPieStyle1] = useState<PieStyle>(defaultPieStyle)
let [pieStyle2, setPieStyle2] = useState<PieStyle>(defaultPieStyle)
let [pieStyle3, setPieStyle3] = useState<PieStyle>(defaultPieStyle)



  let [titlesA, setTitlesA] =useState<string[]>([])
  let [slug, setSlug] = useState("");
  let [gpt, setGpt] = useState("#")
  let [sentimentsA, setSentimentsA] = useState<number[]>([])
  let [summariesA, setSummariesA] = useState<string[]>([])
  let [urlA, setUrlA] = useState<string[]>([])
  let [updatedAt, setUpdatedAt] = useState("")
  let [category, setCategory] = useState("Mythological Creatures")
  let [image, setImage] = useState("https://www.arweave.net/elAbIMOD74awWYccK7wkGLmT-1O19Hqrq6aULqb5bo8?ext=gif")
  let [imagePinterest, setPinterestImage] = useState("")
  let [loadB, setLoadB] = useState(false)
  let [prompt, setPrompt] = useState("")
  let [realisticPrompt, setRealisticPrompt] = useState("")
  let [ancientGlyphPrompt, setAncientGlyphPrompt] = useState("")
  let [sentimentAvg, setSentimentAvg] = useState(0)
  let [fagValue, setFagValue] = useState(0)
  let [amazonProduct, setAmazonProduct] = useState('<iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin" style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=serpentacad04-20&language=en_US&marketplace=amazon&region=US&placement=B0BGPF1ZY5&asins=B0BGPF1ZY5&linkId=fceca2be56e178b4fdb12d92e486904c&show_border=true&link_opens_in_new_window=true"></iframe>')
  let [amazonProductDescription, setAmazonProductDescription] = useState('Check our first book! Dragon and Cobra: Origins of the Ancestral Dragons delves into the fantastical world of two legendary creatures, exploring their histories, mythologies, and cultural impact. The book uncovers their shared origins and enduring legacy.')
  let [labels, setLabels] = useState([])

  let [displayModal, setDisplayModal] = useState("none")
  const [youtubeID, setYoutubeId] = useState('')
  let [domain, setDomain] = useState("localhost")
  const [url, setUrl] = useState("https://ascendance.dev")
  let [isAnonymous, setIsAnonymous] = useState(true)
  let [validAuth, setValidAuth] = useState(false)
  let [coin, setCoin] = useState("")
  function getCurrentURL () {
    return setUrl(window.location.href)
  }

  function countAndSortWords(wordsArray:string[]) {
    const frequencyMap: Record<string, number> = {};
  
    wordsArray.forEach(word => {
      frequencyMap[word] = (frequencyMap[word] || 0) + 1;
    });
  
  
    // Convert the frequency map to an array of [word, count] pairs
    //const sortedArray: [string, number][] = Object.entries(frequencyMap);
    const sortedArray: [string, number][] = Object.entries(frequencyMap);
    // Sort the array based on the frequency
    sortedArray.sort((a, b) => b[1] - a[1]);
  
    sortedArray.slice(0, 10)
    let totalI = 0
    for (var io=0; io < 10; io++){
      totalI += sortedArray[io][1]
    }
  
    let totalWordsSlice = totalI
    return { sortedArray, frequencyMap, totalWordsSlice };
  }
  const auth = getAuth(app);
let db = getFirestore(app)
  let [description, setDescription] = useState("")
  useEffect(() => {
//get if user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log(uid)
    console.log(user)
    setValidAuth(true)
    setIsAnonymous(user.isAnonymous)
    console.log("isAnonymous:" +user.isAnonymous)
    // ...
  } else {
    // User is signed out
    // ...
  }
});


function getPieDimensions(posNI: number,negNI: number, neuNI: number ){
  const sliceDegree1 = posNI ; // *360 40% of 360 degrees
  const sliceDegree2 = negNI ; // 40% of 360 degrees
  const sliceDegree3 = neuNI ; // 40% of 360 degrees


  const pieStyle1 = {
      transform: `rotate(${sliceDegree1}deg)`,
      backgroundColor: 'green' // Set the color of the slice
  };

  const pieStyle2 = {
    transform: `rotate(${sliceDegree2}deg)`,
    backgroundColor: 'red' // Set the color of the slice
};

const pieStyle3 = {
  transform: `rotate(${sliceDegree3}deg)`,
  backgroundColor: 'yellow' // Set the color of the slice
};
console.log("pie: "+pieStyle1.transform+", "+pieStyle2.transform+", "+ pieStyle3.transform)
setPieStyle1(pieStyle1)
setPieStyle2(pieStyle2)
setPieStyle3(pieStyle3)


}

async function getDailyReport(last: string){
  const docRef = doc(db, "sentiment_analysis/indicator/"+slug, last);
  const docSnap = await getDoc(docRef);

if (docSnap.exists()) {

  if (docSnap.data().fagIndex!= null){
    setFagValue(docSnap.data().fagIndex)
  }

  if (docSnap.data().fag_last_updated != null){
    setFag_Last_Updated(docSnap.data().fag_last_updated)
  }

  if (docSnap.data().titles!= null){
    setTitlesA(docSnap.data().titles)
  }

  if (docSnap.data().summaries!= null){
    setSummariesA(docSnap.data().summaries)
  }

  if (docSnap.data().sentimentsA!= null){
    setSentimentsA(docSnap.data().sentimentsA)
  }


  if (docSnap.data().total_negative!= null &&
  docSnap.data().total_neutral!= null &&
  docSnap.data().total_positive!= null
  ){
let negS:string[] = []
//     for (let ia = 0; ia < docSnap.data().total_negative.length; ia++ ){
// negS+= docSnap.data().total_negative[ia]+", "
//     }
//     setNegativeA(negS)

    let neuS:string[] = []
//     for (let ia = 0; ia < docSnap.data().total_neutral.length; ia++ ){
// neuS+= docSnap.data().total_neutral[ia]+", "
//     }
   // setNeutralA(neuS)
    let posS:string[] = []
//     for (let ia = 0; ia < docSnap.data().total_positive.length; ia++ ){
// posS+= docSnap.data().total_positive[ia]+", "
//     }
    


const resultPos = countAndSortWords(docSnap.data().total_positive);
setFreqPos(resultPos.frequencyMap)
setPositiveA(resultPos.sortedArray)
setTotalPosSlice(resultPos.totalWordsSlice)
console.log("totalWordsSlice: "+resultPos.totalWordsSlice)

if (docSnap.data().labels != null){
  const resultLab = countAndSortWords(docSnap.data().labels);
  setFreqLab(resultLab.frequencyMap)
  setLabelsA(resultLab.sortedArray)
  setTotalLabSlice(resultLab.totalWordsSlice)
  console.log("totalWordsSlice: "+resultLab.totalWordsSlice)
  
}


if (docSnap.data().categories != null){
  const resultCat = countAndSortWords(docSnap.data().categories);
  setFreqCat(resultCat.frequencyMap)
  setCategoriesA(resultCat.sortedArray)
  setTotalCatSlice(resultCat.totalWordsSlice)
  console.log("totalWordsSlice: "+resultCat.totalWordsSlice)
  
}


const resultNeg = countAndSortWords(docSnap.data().total_negative);
setFreqNeg(resultNeg.frequencyMap)
setNegativeA(resultNeg.sortedArray)
setTotalNegSlice(resultNeg.totalWordsSlice)
console.log("totalWordsSlice-: "+resultNeg.totalWordsSlice)


const resultNeu = countAndSortWords(docSnap.data().total_neutral);
setFreqNeu(resultNeu.frequencyMap)
setNeutralA(resultNeu.sortedArray)
setTotalNeuSlice(resultNeu.totalWordsSlice)
console.log("totalWordsSliceNeu: "+resultNeg.totalWordsSlice)


    totalK = docSnap.data().total_positive.length+ docSnap.data().total_negative.length +docSnap.data().total_neutral.length
    setTotalK(totalK)
  }
  let pos_1:number = (docSnap.data().total_positive.length/totalK)*100
  let neg_1:number = (docSnap.data().total_negative.length/totalK)*100
  let neut_1:number = (docSnap.data().total_neutral.length/totalK)*100
  setPositiveI(pos_1)
  setNegativeI(neg_1)
  setNeutralI(neut_1)

  getPieDimensions(pos_1, neg_1, neut_1)
  if (docSnap.data().updated_at!= null){
    if (docSnap.data().updated_at+"".startsWith("12")){
      let xStr: string= docSnap.data().updated_at
      xStr = xStr.substring(3, xStr.length)
      xStr = "December "+xStr
      setUpdatedAt(xStr)

    }else{
      setUpdatedAt(updatedAt)

    }
  }

  if (docSnap.data().urls!= null){
    setUrlA(docSnap.data().urls)
  }
  console.log("Document data:", docSnap.data());
  let sentimentsA = docSnap.data().sentimentsA;
  console.log("docSnap.data().sentimentsA"+docSnap.data().sentimentsA)
  let sentimentII:number = 0
  for (let i = 0; i<=sentimentsA.length-1; i++){
    let io:number = parseInt(sentimentsA[i])
    sentimentII+=io
    console.log(sentimentsA[i])
  }
  let avg = sentimentII/sentimentsA.length
  console.log("er"+sentimentII)
  setSentimentAvg(avg)
  
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}

}
  //get domain

  let domainS = window.location.hostname;
  console.log(domainS)
  setDomain("domains: "+domainS)
  let postsS = "sentiment_analysis"
  let metaTagsS = "unknown"
  switch(domainS){
    case "apesascendance.com":
      postsS = "sentiment_analysis"
      metaTagsS ="sentiment_analysis_meta_tags"
      break;
      case "ascendance.dev":
        metaTagsS= "sentiment_analysis_meta_tags"
        postsS = "sentiment_analysis"
        break;
      case "localhost":
        console.log("EUUUUUU")
        postsS = "sentiment_analysis"
        metaTagsS="sentiment_analysis_meta_tags"
  
      break;
  }

  //end getDomain


    getCurrentURL()
    if (loadB){
      return
    }else{
      loadB = true
      setLoadB(loadB)
      const getPosts = async () => {
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /creature/c/
        // /crypto/coin/
        let pathRootI ="/sentiment_analysis/indicator/".length 
        slug = currentpathName.substring(pathRootI, currentpathName.length)
        console.log("slug: "+currentpathName.substring(30, currentpathName.length))

        setSlug(slug)

        // /p/crow-spirit-visions/p/crow-spirit-visions/
     


        const docRef = doc(db, postsS, slug);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          title = docSnap.data().title
          gpt = docSnap.data().gpt
          setGpt(gpt)
          setCoin(docSnap.data().coin)
          description = docSnap.data().description
          console.log("summary: "+description)
          setTitle(title)
          image = docSnap.data().image
          prompt = docSnap.data().prompt

          setImage(image)
          setPrompt(prompt)
          setRealisticPrompt(docSnap.data().realisticPrompt)
          setAncientGlyphPrompt(docSnap.data().ancientGlyphPrompt)
          setPinterestImage(image)
          if (docSnap.data().labels != null){
            let labelsA =  docSnap.data().labels.split(", ");
            setLabels(labelsA)

          }
          setCategory("Article")
          if (docSnap.data().amazon != null && docSnap.data().amazon != "")
          {
            setAmazonProduct(docSnap.data().amazon)
            console.log(amazonProduct)
          }
          if (docSnap.data().amazon_description != null && docSnap.data().amazon_description != "")
          {
            setAmazonProductDescription(docSnap.data().amazon_description)
            console.log(amazonProductDescription)
          }
          setYoutubeId(docSnap.data().youtubeId)
          //console.log(docSnap.data().youtubeId)
          setDescription(description)
        setLastDoc(docSnap.data().last)
          getDailyReport(docSnap.data().last)

        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      }
        getPosts()
    }
  

   //signUpAnonymously

  
  },[imagePinterest]);
  async function GetPost(){


  }
  function signUpAnonymously(){
    //if no AUTH signup anonymously

    //a) Check AUTH
if (!validAuth){
//signup anonymously
signInAnonymously(auth)
  .then(() => {
    // Signed in..
    console.log("signed anonymously")
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
}else{
    if (isAnonymous== true){

    }if (isAnonymous== false){

    }

}


    //b) IF no AUTH signup anonymously
  }


  const getColor = (fagValue: number) => {
    if (fagValue < 25) return '#ff4500'; // Red for extreme fear
    if (fagValue < 50) return '#ffa500'; // Orange for fear
    if (fagValue < 75) return '#ffd700'; // Yellow for greed
    return '#008000'; // Green for extreme greed
};
  function closeModal(){
    
    setDisplayModal("none")
  }

    return (
      <>
        <main>
        
       
       
       
       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3 className='cryptoClass'>Sentiment Analysis</h3>
       <p> <span className='whiteSmoke'>Last updated: </span><span className='lastUpdated'>{updatedAt}</span></p>
       <p className='cryptoClass'> 
       <span className='cryptoClass'>We used our unique methodology to measure <strong>{coin}</strong> sentiment</span></p>


       </div>
       </div>
       
       
       <div className="grid-item">
       <h2 className='promptTitle'>{coin}: Sentiment Analysis Score by analyzing news, with our custom Algorithm</h2>
       
     
    <input className='progress-bar' type="range" min="0" max="100" value={parseInt(sentimentAvg+"")} id="sentimentRange" />

       
       <div className='grid-container-2'>
       <div className='grid-item'>
        <h3>Score: <strong><span className='postive_score'>{parseInt(sentimentAvg+"")}</span>/100</strong></h3>
       </div>
       
       <div className='grid-item'></div>
       
       
       </div>
     
       </div>
       
       </div>



       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3 className='cryptoClass'>Fear and Greed Index</h3>
       <h3 className='lastUpdated'>{fag_last_updated}</h3>
       </div>
       </div>
       

       





       
       
       <div className="grid-item">
       <h2 className='promptTitle'>Fear and Greed Investor Indicator: The idea is to compare the global Indicator.   </h2>
       
       <div className="fear-greed-container">
            <div className="fear-greed-bar" style={{ width: `${fagValue}%`, backgroundColor: getColor(fagValue) }}></div>
            <div className="fear-greed-label">Fear & Greed Index <strong>{fagValue} </strong> <br></br>
            source: <a href="https://edition.cnn.com/markets/fear-and-greed">CNN</a>
            </div>
        </div>
       
       <div className='grid-container-2'>
       <div className='grid-item'></div>
       
       <div className='grid-item'></div>
       
       
       </div>
     
       </div>
       
       </div>




       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3  className='cryptoClass'><span className='posKeyword'>Positive {Math.round(positiveI * 100) / 100}%</span> Keywords</h3>
       <h3 className='cryptoClass'><span className='negKeyword'>Negative {Math.round(negativeI*100)/100}%</span> Keywords</h3>
       <h3 className='cryptoClass'><span className='neuKeyword'>Neutral {Math.round(neutralI*100)/100}%</span> Keywords</h3>

   
       <div className="pieContainer">
    <div className="pieBackground"></div>
    <div id="pieSlice1" className="hold">
    <div className="pie" style={pieStyle1}></div>
</div>
    <div id="pieSlice2" className="hold">
      <div className="pie" style={pieStyle2}></div></div>
    <div id="pieSlice3" className="hold">
      <div className="pie" style={pieStyle3}></div></div>
    <div className="innerCircle">
        <div className="content"><b>Words</b><br/>from {updatedAt}</div>
    </div>
</div>


       </div>
       </div>
       
       
       <div className="grid-item">
       <h2 className='promptTitle'>{coin}: Total Keywords Extracted: <strong>{totalK}</strong></h2>
       
       
       
       <div className='grid-container-1 '>
  


 <div className='grid-item greyBG'>       

       Positive words: 
       <div className="range-container">
        <label >Positive: <strong>{parseInt(positiveI+"")}</strong>/100 words</label>
        <input type="range" id="positive" name="positive" min="0" max="100" value={positiveI} className="sentiment-range" />
</div>
</div>
       <div className='grid-item'>
       {positiveA.slice(0,10).map((wordS,index)=> (
               <div className='grid-container-2'>

        <div className='grid-item'>{positiveA[index][0]}: {Math.round(freqPos[positiveA[index][0]]/totalPosSlice*100)}%
        </div>
        <div className='grid-item'>
        <div className="range-container">
        <input type="range" id="neutral" name="positive" min="0" max="100" value={Math.round(freqPos[positiveA[index][0]]/totalPosSlice*100)} className="sentiment-range" />
</div>
</div>
       
        </div>
       ))}
     </div>


     <div className='grid-item greyBG'>       

       Negative words: 
       <div className="range-container">
        <label >Negative: <strong>{parseInt(negativeI+"")}</strong>/100 words</label>
        <input type="range" id="negative" name="positive" min="0" max="100" value={negativeI} className="sentiment-range" />
</div>
</div>

     


<div className='grid-item'>
       {negativeA.slice(0,10).map((wordS,index)=> (
               <div className='grid-container-2'>

        <div className='grid-item'>{negativeA[index][0]}: {Math.round(freqNeg[negativeA[index][0]]/totalNegSlice*100)}%
        </div>
        <div className='grid-item'>
        <div className="range-container">
        <input type="range" id="neutral" name="positive" min="0" max="100" value={Math.round(freqNeg[negativeA[index][0]]/totalNegSlice*100)} className="sentiment-range" />
</div>
</div>
       
        </div>
       ))}
     </div>





     <div className='grid-item greyBG'>       

Neutral words: 
<div className="range-container">
 <label >Neutral: <strong>{parseInt(neutralI+"")}</strong>/100 words</label>
 <input type="range" id="neutral" name="positive" min="0" max="100" value={neutralI} className="sentiment-range" />
</div>
</div>

  
      






<div className='grid-item'>
       {neutralA.slice(0,10).map((wordS,index)=> (
               <div className='grid-container-2'>

        <div className='grid-item'>{neutralA[index][0]}: {Math.round(freqNeu[neutralA[index][0]]/totalNeuSlice*100)}%
        </div>
        <div className='grid-item'>
        <div className="range-container">
        <input type="range" id="neutral" name="positive" min="0" max="100" value={Math.round(freqNeu[neutralA[index][0]]/totalNeuSlice*100)} className="sentiment-range" />
</div>
</div>
       
        </div>
       ))}
     </div>
     </div>







       
       </div>
     
       </div>






       <div className="grid-container-2 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3 className='cryptoClass'>{coin}: Labels, Categories</h3>
       <h3 className='lastUpdated'>Top News, Trends, Businnes Opportunities, Content Creation Ideas</h3>
       </div>
       </div>


<div className='grid-cointainer'>
       <div className="grid-item">
       <h2 className='promptTitle'>Top Labels and Categories for {coin}.   </h2>
       
    
       
   
       <div className='grid-item greyBG'>       

Labels: 


       
       
       </div>
     
       </div>
       <div className='grid-item'>
       {labelsA.slice(0,10).map((wordS,index)=> (
               <div className='grid-container-2'>

        <div className='grid-item'>{labelsA[index][0]}: {Math.round(freqLab[labelsA[index][0]]/totalLabSlice*100)}%
        </div>
        <div className='grid-item'>
        <div className="range-container">
        <input type="range" id="neutral" name="positive" min="0" max="100" value={Math.round(freqLab[labelsA[index][0]]/totalLabSlice*100)} className="sentiment-range" />
</div>
</div>
       
        </div>
       ))}
       </div>
  
       <div className='grid-item greyBG'>       

Categories: 
      </div>
       <div className='grid-item'>
       {categoriesA.slice(0,10).map((wordS,index)=> (
               <div className='grid-container-2'>

        <div className='grid-item'>{categoriesA[index][0]}: {Math.round(freqCat[categoriesA[index][0]]/totalCatSlice*100)}%
        </div>
        <div className='grid-item'>
        <div className="range-container">
        <input type="range" id="neutral" name="positive" min="0" max="100" value={Math.round(freqLab[categoriesA[index][0]]/totalCatSlice*100)} className="sentiment-range" />
</div>
</div>
       
        </div>
       ))}
       </div>


       </div>

       </div>






       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3 className='cryptoClass'>Top news: {slug}</h3>
<p className='lastUpdated'> Top {slug} news from: {updatedAt}</p>

       </div>
       </div>
       
       
       <div className="grid-item">
       <h2 className='promptTitle'>{coin}: Last Top 5-10 news of most relevant crypto news websites, with Sentiment Analysis</h2>
       {titlesA.map((titleS,index)=> (

<div className="article">
<hr></hr>
    <h2>{titleS}</h2>
    <p>{summariesA[index]}</p>
    <a className='readArticle' href={urlA[index]}> Read Article</a>
    <div className="progress-container">
       <input type='range' value={sentimentsA[index]}></input> 
    </div>
    <hr></hr>
</div>
       ))}

       
       
       <div className='grid-container-2'>
       <div className='grid-item'></div>
       
       <div className='grid-item'></div>
       
       
       </div>
       <div className="grid-item">
       
       </div>
       
       </div>
       <div className="grid-item">
       </div>
       </div>











       

       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <h3 className='cryptoClass'>Business Intelligence</h3>
       <h3 className='cryptoClass'>Actions</h3>
       <h3 className='cryptoClass'>Business Strategy</h3>


       </div>
       </div>
       
       
       <div className="grid-item">
       <h2 className='promptTitle'>{coin}: Top business strategy, actions, business intelligence {coin} core strategies</h2>
       
       
       
       <div className='grid-container-2'>
       <div className='grid-item'></div>
       <ul>
      <li>✅ Tweet using #{slug} tag by sharing value and empowering the industry</li>
     <br></br>
      <li>✅ Educate yourself about {coin}, Read Papers, Encyclopedia Articles</li>
      <br></br>

      <li>✅ Engage with others devs and by emailing media, politicians to empower a responsible {coin} Industry</li>

 
       </ul>
       <div className='grid-item'></div>
       
       
       </div>
       <div className="grid-item">
       
       </div>
       
       </div>
       <div className="grid-item">
       </div>
       </div>


       <div className="grid-container-3 bgmaincard">
       
       <div className="grid-item bgmaincard" >
       <div className='card' >
       <img className='card' src={image} alt={title} />
       <p className='lastUpdated'>last updated: <span>{updatedAt}</span></p>

       </div>
       </div>
       







       
       <div className="grid-item">
       <h2 className='promptTitle'>{title}</h2>
       
       
       
       <div className='grid-container-2'>
       <div className='grid-item'></div>
       
       <div className='grid-item'></div>
       
       
       </div>
       <div className="grid-item">
       <a href={gpt} ><button className= "buybutton"  >Learn Rust for Solana Development GPT</button></a>
       <br/>
       <p>** Free GPT if you have a CHATGPT4 Plus Membership</p>
       <br/><hr />
       </div>
       
       </div>
       <div className="grid-item">
       </div>
       </div>


       

       <div className="grid-item">
       </div>


        <br>
        </br>
        <h2>Category: {category}</h2>
       
        <h2>Prompt</h2>
        <p>{prompt}</p>
        <h2>Realistic Prompt</h2>
        <p>{realisticPrompt}</p>
        <h2>Ancient Glyph Prompts</h2>
        <p>{ancientGlyphPrompt}</p>
        <div className='skillsharesub'>
          <a href='https://www.skillshare.com/en/r/user/serpentacademy?gr_tch_ref=on'>
    <h2>Get 1 Month Free Skillshare</h2>
    <h3>Unleash your creativity with Skillshare! Merge tech with art 🎨💻✨. Ignite your imagination, explore endless possibilities. #CreativityUnleashed #TechMeetsArt #SkillshareExperience
</h3>
    <img className='imgSkillShare' src="https://arweave.net/mUgjIs_emUqyWimDyNElt1wT3BMRkvUbf3TfWw-ARVs"></img>
    </a>
  </div>
        {imagePinterest!=""?
<a     data-pin-count="true" 
       className="pinterestSave" 
       data-pin-media={imagePinterest} 
       data-pin-description={description} 
       data-pin-url={"https://ascendance.dev/p/"+slug+"/"} 
       href={"https://www.pinterest.com/pin/create/button/?url=https://ascendance.dev/p/"+slug+"/&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)} 
       data-pin-do="buttonPin">
        <img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"
  ></img>

       <span className='pinText'>Save</span>   </a>:"" }
       

       <h2>Top Articles and Classes</h2>

       <div className="tarjeta">
  <div className="contenido">
      <div className="ladoIzq">
      <a href="https://skl.sh/46cUEXF"><h2 className="titulo">Master 3D Alien Planets Creation using AI
</h2></a>
      <div className="cuerpo">
      <p className='pRec'>👽🌍🚀 Master 3D alien planet mapping with Midjourney AI, Adobe Substance 3D Sampler, and Blender! Join today! #3DMapping #AlienWorlds #Skillshare 🎨🖥️🌌 </p>
            <a className="btnRec" href="https://skl.sh/46cUEXF">Take Skillshare Class Free</a>
      </div>
   </div>
    <div className="ladoDer">
    <a href="https://skl.sh/46cUEXF">
    <br /><br /><br /><br />
      <img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/ovhz-Ri2Decuma2eim9FH1Wio8Pkhua9u2l1ly8t_5Q" /></a></div>
    </div>
</div>



<div className="tarjeta">
  <div className="contenido">
      <div className="ladoIzq">
      <a href="https://skl.sh/3SonC3p"><h2 className="titulo">Transform photos into 3D objects with Photogrammetry using AI
</h2></a>
      <div className="cuerpo">
      <p className='pRec'>Transform photos into 3D objects 📸💻🎨. Our Skillshare class on Photogrammetry using Adobe Substance 3D Sampler awaits you! #Adobe3D #Photogrammetry #Skillshare </p>
            <a className="btnRec" href="https://skl.sh/3SonC3p">Take Skillshare Class Free</a>
      </div>
   </div>
    <div className="ladoDer">
    <a href="https://skl.sh/3SonC3p">
    <br /><br /><br /><br />
      <img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/qypEGfML1D1Q_l5wGTnCUM9hZRykvv-YcWLnEIQygfc" /></a></div>
    </div>
</div>


<div className="tarjeta">
  <div className="contenido">
      <div className="ladoIzq">
      <a href="https://skl.sh/3FvhgHO"><h2 className="titulo">Boat Buoyancy on a Primitive Ocean Unreal Engine 5, Adobe Substance 3D Sampler and Midjouney
</h2></a>
      <div className="cuerpo">
      <p className='pRec'>
      Master Unreal Engine 5, Adobe Substance 3D & Midjourney in our Skillshare class, Boat Buoyancy on a Primitive Ocean. 🌊🚤🏝️ Create games, films with realistic oceans! #GameDev #3DModeling #SkillshareClass
 </p>
            <a className="btnRec" href="https://skl.sh/3FvhgHO">Take Skillshare Class Free</a>
      </div>
   </div>
    <div className="ladoDer">
    <a href="https://skl.sh/3FvhgHO">
    <br /><br /><br /><br />
      <img className='artrecImg' style={{width:"380px",height:"233px"}} src="https://arweave.net/m2Z11tsqt7ztejLQi3-LJexwb1GgtFZOBFrO38HrCXY" /></a></div>
    </div>
</div>




          <h2>{title}</h2>
          <div className='divcontent' style={{alignContent:left}} dangerouslySetInnerHTML={{__html: description}}    />
          <br></br><br></br>
         <div id="labels">{labels.map(label => {
    return <span className='label'>{label}</span>
}) 

}</div>
<br/><br/><br/>
   



        </main>
         
<div style={{display: displayModal}} id="myModal" className="modal">

  <div className="modal-content">
    <span onClick={closeModal} className="close">&times;</span>
    <p>Subscribe to Email and Push Notifications for FREE BOOKS</p>
  </div>

</div>
        <div className='content'>
        
 


          <div> <h2>Share this article to show love ❤️:</h2></div>
<br></br>

       <br />  <br />  <br />
      <FacebookShareButton
        url={url}
        quote={title}
        hashtag={category}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

    </div>
        <nav>
          <Link to="/about">About</Link>
        </nav>
        <footer className="footerSocial">
          Help us spread love! ❤️
        {imagePinterest!=""?
<a 
       data-pin-count="true" 
       className="pinterestSave" 
       data-pin-media={imagePinterest} 
       data-pin-description={description} 
       data-pin-url={url+"/p/"+slug+"/"} 
       href={"https://www.pinterest.com/pin/create/button/?url="+url+"&media="+encodeURIComponent(imagePinterest)+"&description="+encodeURIComponent(title)} 
       data-pin-do="buttonPin">
        <img className='pinImg'
  src="https://arweave.net/gtEFZCe6UFFWLHhl1T0-n7c-Rk5BOt2RoY_Cw4mlCZY" 
  width="33" 
  height="33"
  ></img>
       <span className='pinText'>Save</span>   </a>:"" }
</footer>
      </>
      
    );
  }
  export default Post;