import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { collection, query, where, getDocs, DocumentData, getFirestore } from "firebase/firestore";
import app from "../firebaseapp"
import { Editor } from '@tinymce/tinymce-react';
import "../css/write.css"
import { doc, setDoc, updateDoc } from "firebase/firestore"; 

import {onAuthStateChanged, getAuth  } from "firebase/auth";
function Write() {
  const [buffer, setBuffer] = useState<any[]>([])
  const [slug, setSlug] = useState('');
  const [title, setTitle] = useState('');
  const [article, setArticle] = useState('');
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("")
  const [youtubeId, setYoutubeId] = useState("")
  const [description, setDescription] = useState("")
  const auth = getAuth(app);
  const [price, setPrice] = useState(1)
  const [version, setVersion] = useState("")
  const [domain, setDomain] = useState("apespost")
  const [imagesArrayHMTL, setImagesArrayHMTL] = useState(<input id="image1" placeholder='image1'></input>)
  const [imagesAI, setImagesAI] = useState(1)
  const[imagesA, setImagesA] = useState<string[]>([])
  let db =getFirestore(app)


  const [visibility1, setVisibility1] = useState(true)
  const [visibility2, setVisibility2] = useState(true)
  const [visibility3, setVisibility3] = useState(true)


  const [productId, setProductId] = useState("")


  const [title1, setTitle1] = useState("")
  const [title2, setTitle2] = useState("")
  const [title3, setTitle3] = useState("")

  const [description1, setDescription1] = useState("")
  const [description2, setDescription2] = useState("")
  const [description3, setDescription3] = useState("")

  const [instruction1, setInstruction1] = useState("")
  const [instruction2, setInstruction2] = useState("")
  const [instruction3, setInstruction3] = useState("")

  const [key1, setKey1] = useState("")
  const [key2, setKey2] = useState("")
  const [key3, setKey3] = useState("")






  useEffect(() => {



    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(uid)
        // ...
      } else {
        // User is signed out
        // ...
      }
    });
    
  }, [])


  function convertToSlug(Text: String) {
    console.log(Text)
    setTitle(Text.toString())
    return setSlug(Text.toLowerCase()
              .replace(/ /g, '-')
              .replace(/[^\w-]+/g, ''));
  }
      
function Save(){
console.log("saving")
console.log("artcicle: "+ article)
saveDocument()

}
function handleEditorChange(e:any ){
console.log('Content was updated:', e.target.getContent());
setArticle( e.target.getContent());
}
async function saveDocument(){

    //get domain

    let domainS = window.location.hostname;
    console.log(domainS)
    setDomain("domains: "+domainS)
    let postsS = "prompt"
    let metaTagsS = "meta_tags"
    
  
    //end getDomain

// set doc
var tempNamespace = {};
const str = "category_"+category;
// Create an initial document to update.
const postRef = doc(db, postsS, slug);
await setDoc(postRef, {
  description: article,
  title: title,
  category: category,
  [str] : true,
  revision: "1.0",
  twitter_image: image,
  price: price,
  imagesA: imagesA,
  version: version,
  title1, 
  title2,
  title3, 
  description1,
  description2,
  description3,
  instruction1,
  instruction2,
  instruction3,
  key1,
  key2,
  key3, 
  visibility1, 
  visibility2, 
  visibility3, 
  slug,
  productId,

});
const metaRef = doc(db, metaTagsS, slug);

await setDoc(metaRef, {
OG_DESCRIPTION: description,
OG_TITLE: title,
OG_URL: "https://ascendance.dev/prompt/"+slug+"/",
OG_IMAGE: image,
TWITTER_DYNAMIC_TITLE: title
});



}

function saveImageInArray(imgSrc:string, numberOdImgI: number){
imagesA[numberOdImgI] = imgSrc
}

function addImageToArray(){
    setImagesAI(imagesAI+1)
buffer.push(<input placeholder={imagesAI +" Image"} id={"image"+imagesAI} onChange={e=>saveImageInArray(e.target.value, imagesAI-1)}></input>)
    
   setBuffer(buffer)
    
}

    return (
      <>
      <div id='write_article_div'>
<h1>Write a Prompt:</h1>
<input value={title} onChange={e => convertToSlug(e.target.value)} id='title' className='title' type='text' placeholder='Prompt Title'></input>
<textarea value={description} onChange={e=> setDescription(e.target.value)} id='description' className='description'  placeholder='Prompt Description'></textarea>
<input id='slug' disabled value={slug} />
<input id='category' value={category} onChange={e => setCategory(e.target.value)} placeholder='category'/>
<input id='price' type='number' value={price} onChange={e => setPrice(parseFloat(e.target.value))} placeholder='price' />
<input value={version} onChange={e=> setVersion(e.target.value)} id='version' className='description'  placeholder='AI model version'></input>

 
<input id='image' placeholder='image source' value={image} onChange={e=> setImage(e.target.value)} />

<Editor apiKey='kaulizd7eylxst9mi2uitk51uxiiqt4iqwzuze97jnhs4pad' 

   init={{ plugins: 'link image code',
   toolbar: 'undo redo | bold italic| alignleft aligncenter alignright | code'}}
   onChange={e => handleEditorChange(e)}      />

<br />
   <div id="imagesArray">

   {buffer.map(item =>{
    return item
   })}
   <br></br>
   <input placeholder='productId' onChange={e=>{setProductId(e.target.value)}}/>
<br></br>
<button onClick={addImageToArray} className='buybutton'>Add Image +</button>
<br></br>
<input placeholder="Title 1" id="title1" onChange={e=>setTitle1(e.target.value)}></input>
<input placeholder="Instruction 1" id="instruction1" onChange={e=>setInstruction1(e.target.value)}></input>
<input placeholder="key 1" id="key1" onChange={e=>setKey1(e.target.value)}></input>
<input type='checkbox' checked={visibility1} id='visibility1' onChange={e=>setVisibility1(Boolean(e.target.value))}></input>
<textarea placeholder="description 1" id="description1" onChange={e=>setDescription1(e.target.value)}></textarea>
<br />

<input placeholder="Title 2" id="title2" onChange={e=>setTitle2(e.target.value)}></input>
<input placeholder="Instruction 2" id="instruction2" onChange={e=>setInstruction2(e.target.value)}></input>
<input placeholder="key 2" id="key2" onChange={e=>setKey2(e.target.value)}></input>
<input type='checkbox' checked={visibility2} id='visibility2' onChange={e=>setVisibility2(Boolean(e.target.value))}></input>


<textarea placeholder="description 2" id="description2" onChange={e=>setDescription2(e.target.value)}></textarea>

<br />

<input placeholder="Title 3" id="title3" onChange={e=>setTitle3(e.target.value)}></input>
<input placeholder="Instruction 3" id="instruction3" onChange={e=>setInstruction3(e.target.value)}></input>
<input placeholder="key 3" id="key3" onChange={e=>setKey3(e.target.value)}></input>
<input type='checkbox' checked={visibility3} id='visibility3' onChange={e=>setVisibility3(Boolean(e.target.value))}></input>

<textarea placeholder="description 3" id="description3" onChange={e=>setDescription3(e.target.value)}></textarea>

   </div>
<button id='save' onClick={Save}><span className='buybutton'>Save</span> </button>
      </div>
      
      </>
    );
  }
  export default Write;