import {Link} from 'react-router-dom'
import {useEffect, useState} from 'react'
import { loadStripe } from "@stripe/stripe-js"
import "../css/success.css"
import app from "../firebaseapp"
import { getDoc, doc, getFirestore, where, query, collection, getDocs, updateDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
function Success() {
let db = getFirestore(app)
const auth = getAuth(app);


    useEffect(() => {
        let params = new URLSearchParams(window.location.search)

        if (params.get('id') !=null && params.get('id') != "" ){
            let id:string = params.get('id')+""

            console.log(id)
            getSession(id+"")    
            onAuthStateChanged(auth, (user) => {
                if (user) {
                  // User is signed in, see docs for a list of available properties
                  // https://firebase.google.com/docs/reference/js/auth.user
                  const uid = user.uid;
                  getProductIdAndSaveOnCustomer(uid, id)
                  // ...
                } else {
                  // User is signed out
                  // ...
                }
              });
        }
//get domain
    }, [])

      function getSession(id: string){
        console.log("id: "+id)
        const stripePromise = loadStripe(
           "pk_live_51Ny2b5BKvAAbP7RKpcgLJ6KBFnx6AqNj4kC8GxPXUtFM8ma8BvIGrA4lXqnx9JgYkgzbW6NjH4GaODXDJXVrqb4M00RKRjRd4Z")
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id })
      };
      fetch('https://ascendance.dev/api/getStripeCheckout/', requestOptions)
          .then(async response => {
              const isJson = response.headers.get('content-type')?.includes('application/json');
              const data = isJson && await response.json();
      
              console.log("payment_status: "+data.payment_status)
              // check for error response
              if (!response.ok) {
                  // get error message from body or default to response status
                  const error = (data && data.message) || response.status;
                  return Promise.reject(error);
              }
      
      console.log(data)
      let payment_intent= data.payment_intent;
      let payment_status = data.payment;
      console.log(JSON.stringify(data))

      //save on users/{uid}/


     
      
          })
          .catch(error => {
              //this.setState({ errorMessage: error.toString() });
              console.error('There was an error!', error);
          });
      }

     async function getProductIdAndSaveOnCustomer(uid: string, idS: string){

        const q = query(collection(db, "checkout-session/"+uid+"/session"), where("id", "==", idS));

const querySnapshot = await getDocs(q);
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());

  saveCourse(uid, doc.data())

  //save on user

});
      

}
async function saveCourse(uid: string, data: any){
  const userRef = doc(db, "users", uid) 
let prodIdKey = "product_"+data.productId
let theProductType = "prompt";
let url_category = "prompt";
let acces_category = "";
if (data.productId.startsWith("3D_model_")){
  theProductType = "models_3D";
  url_category = "3d_model";
  acces_category = "3D_model_"

}

const promptq = query(collection(db, theProductType), where("productId", "==", data.productId));

const docSnap = await getDoc(userRef);

const querySnapshot = await getDocs(promptq);
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());
  console.log(doc.data().slug)
  saveCourseComplete(uid, prodIdKey, data.productId, doc.data().slug, url_category, acces_category)

});



}
async function saveCourseComplete(uid: string, prodIdKey: string, productId:string, slug:string, url_category: string, acces_category: string){
  const userRef = doc(db, "users", uid) 

  await updateDoc(userRef, {["product_3D_model_"+prodIdKey]: productId, [acces_category+slug]: true})
  window.location.replace("/"+url_category+"/"+slug+"/")


}    

  

    return (
      <>
       <div>
<h1 id='succesful_payment'>Successful Payment - Thank you! We are redirecting YOU :)</h1>
<pre id='session_data'></pre>


       </div>
       
      </>
    );
  }
  export default Success;