import "../css/Footer.css"
function Footer() {
    return (<>
    
    <div className="grid-item about">
    <br/><br/><br/><br/><br/><br/><br/>
                <div className="grid-item">About</div><h2>Ascendance.dev</h2>
            <ul><li>We are building the the greatest web3 and AI marketplace powered by Hard Work .</li><br></br>
            <li>Empowering humans work with AI, web2, web3</li></ul>
            <p><a href="/whitepaper/">Read our Alpha White Paper</a></p></div>

    </>  );
}

export default Footer;